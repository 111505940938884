import axios from 'axios';
import { AuthResponse, User } from '@/contexts/auth/types';

export const api = axios.create({
  baseURL: '/api', // Using the Vite proxy
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

// Interceptor para adicionar o token em todas as requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('auth-token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const auth = {
  async signUp(email: string, password: string, name: string): Promise<AuthResponse> {
    try {
      console.log('Sending signup request:', { email, name, password: '***' });
      const response = await api.post<AuthResponse>('/auth/signup', { email, password, name });
      console.log('Signup response:', { 
        success: true, 
        userId: response.data.user?.id,
        email: response.data.user?.email
      });
      return response.data;
    } catch (error) {
      console.error('Error in signUp:', error);
      if (error.response) {
        console.error('Server response:', {
          status: error.response.status,
          data: error.response.data
        });
      }
      throw error;
    }
  },

  async signIn(email: string, password: string): Promise<AuthResponse> {
    try {
      const response = await api.post<AuthResponse>('/auth/signin', { email, password });
      return response.data;
    } catch (error) {
      console.error('Error in signIn:', error);
      throw error;
    }
  },

  async signOut(): Promise<void> {
    try {
      await api.post('/auth/signout');
      localStorage.removeItem('auth-token');
    } catch (error) {
      console.error('Error in signOut:', error);
      throw error;
    }
  },

  async verifyToken(token: string): Promise<User | null> {
    try {
      const response = await api.get<{ user: User }>('/auth/verify', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data.user;
    } catch (error) {
      console.error('Error in verifyToken:', error);
      return null;
    }
  }
}; 