import { useState, useEffect } from 'react';
import { api } from '@/integrations/database/auth';
import { EBook } from '@/components/ebooks/EBookCard';
import { useAuth } from '@/contexts/AuthContext';

// Define interface matching the ACTUAL API response (likely camelCase from Prisma)
interface BookApiResponse {
  id: string;
  title: string;
  author: string;
  description: string | null; // Match Prisma schema nullability
  coverImageUrl: string | null;  // Changed from coverUrl to match server field name
  filePath: string;            // Changed from fileUrl to match server field name
  totalPages?: number | null; // Add totalPages if API returns it
  isPremium: boolean;
  categories?: { id: string; name: string }[];
  isFavoritedByUser?: boolean;
  // Add other fields returned by the API if necessary
}

export function useBooks() {
  const [books, setBooks] = useState<EBook[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user, isLoading: isLoadingAuth } = useAuth();

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        console.log('[useBooks] Attempting to fetch books...');
        setIsLoading(true);
        setError(null);
        const response = await api.get<BookApiResponse[]>('/books');
        console.log('[useBooks] API response received:', response.data);
        
        // DEBUG: Encontrar livro específico por ID
        const debugBook = response.data.find(book => book.id === '59406880-98a0-4326-97d1-8c158e40ff96');
        if (debugBook) {
          console.log('[useBooks] Livro problemático antes do mapeamento:', debugBook);
        }
        
        // Map the ACTUAL API response fields to the EBook interface fields
        const mappedBooks: EBook[] = response.data.map((book) => ({
          id: book.id,
          title: book.title,
          author: book.author,
          description: book.description || '',
          coverImageUrl: book.coverImageUrl,
          filePath: book.filePath || '',
          categories: book.categories || [],
          totalPages: book.totalPages,
          isPremium: book.isPremium,
          isFavoritedByUser: book.isFavoritedByUser
        }));
        
        console.log('[useBooks] Mapped books:', mappedBooks);
        setBooks(mappedBooks);
      } catch (err) {
        const error = err instanceof Error ? err : new Error('Failed to fetch books');
        setError(error);
        console.error('[useBooks] Error fetching books:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Only fetch books if auth is not loading and user exists
    if (!isLoadingAuth && user) {
      console.log('[useBooks] Auth ready and user exists. Fetching books.');
      fetchBooks();
    } else if (!isLoadingAuth && !user) {
        console.log('[useBooks] Auth ready but no user logged in. Skipping book fetch.');
        // Set books to empty and stop loading if user logs out
        setBooks([]);
        setIsLoading(false); 
        setError(null);
    } else {
      console.log('[useBooks] Auth is still loading. Waiting...');
      // Keep loading true while auth is resolving
      setIsLoading(true);
    }

  }, [user, isLoadingAuth]);

  return { books, isLoading, error };
} 