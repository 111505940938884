import { useState, useEffect } from "react";
import EBookCard, { EBook } from "@/components/ebooks/EBookCard";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Search, Filter, X } from "lucide-react";
import { api } from "@/integrations/database/auth";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Checkbox } from "@/components/ui/checkbox"
import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/utils";

interface Category {
  id: string;
  name: string;
}

interface EBookWithCategories extends Omit<EBook, 'category'> {
  categories: Category[];
}

interface EBookGridProps {
  books: EBookWithCategories[];
  title?: string;
  subtitle?: string;
}

const EBookGrid = ({ books, title, subtitle }: EBookGridProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  
  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const response = await api.get<Category[]>('/categories');
        const validCategories = response.data.filter(cat => cat && cat.name);
        setAllCategories(validCategories);
        console.log('[EBookGrid] Fetched categories for filter:', response.data);
        console.log('[EBookGrid] Valid categories set for filter:', validCategories);
      } catch (error) {
        console.error("Erro ao buscar categorias para filtro:", error);
      } finally {
        setIsLoadingCategories(false);
      }
    };
    fetchCategories();
  }, []);
  
  const filteredBooks = books.filter(book => {
    const matchesSearch = !searchQuery || 
      book.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
      book.author.toLowerCase().includes(searchQuery.toLowerCase());
      
    const matchesCategory = selectedCategories.length === 0 || 
      (book.categories && book.categories.some(cat => selectedCategories.includes(cat.name)));
    
    return matchesSearch && matchesCategory;
  });

  const handleCategoryToggle = (categoryName: string) => {
    setSelectedCategories(prev => 
      prev.includes(categoryName)
        ? prev.filter(name => name !== categoryName)
        : [...prev, categoryName]
    );
  };

  return (
    <div className="space-y-6">
      {(title || subtitle) && (
        <div className="text-center max-w-3xl mx-auto mb-8">
          {title && <h2 className="text-3xl font-bold tracking-tight mb-3">{title}</h2>}
          {subtitle && <p className="text-muted-foreground">{subtitle}</p>}
        </div>
      )}
      
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="relative flex-grow">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            type="text"
            placeholder="Search by title or author..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-10"
          />
        </div>
      
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" className="w-full sm:w-auto justify-start">
              <Filter className="mr-2 h-4 w-4" />
              Categories
              {selectedCategories.length > 0 && (
                <Badge variant="secondary" className="ml-2 rounded-sm px-1 font-normal">
                  {selectedCategories.length}
                </Badge>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0" align="start">
            <Command>
              <CommandInput placeholder="Filter categories..." />
              <CommandList>
                <CommandEmpty>No categories found.</CommandEmpty>
                <CommandGroup>
                  {isLoadingCategories ? (
                    <CommandItem disabled>Loading...</CommandItem>
                  ) : (
                    allCategories.map((category) => (
                      <CommandItem 
                        key={category.id}
                        onSelect={() => handleCategoryToggle(category.name)}
                        className={cn(
                          "cursor-pointer",
                          selectedCategories.includes(category.name) && "bg-blue-100 dark:bg-blue-900"
                        )}
                      >
                        <Checkbox
                          className="mr-2 h-4 w-4"
                          checked={selectedCategories.includes(category.name)}
                          onCheckedChange={() => handleCategoryToggle(category.name)}
                        />
                        <span>{category.name}</span>
                      </CommandItem>
                    ))
                  )}
                </CommandGroup>
                {selectedCategories.length > 0 && (
                  <CommandGroup className="border-t pt-1 mt-1">
                     <CommandItem
                      onSelect={() => setSelectedCategories([])}
                      className="justify-center text-center text-xs text-muted-foreground cursor-pointer"
                    >
                      Clear filters
                    </CommandItem>
                  </CommandGroup>
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        {selectedCategories.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-4">
            {selectedCategories.map((categoryName) => (
              <Badge key={categoryName} variant="secondary" className="px-2 py-0.5">
                {categoryName}
                <button 
                  onClick={() => handleCategoryToggle(categoryName)} 
                  className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              </Badge>
            ))}
          </div>
        )}
      </div>
      
      {filteredBooks.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredBooks.map(book => (
            <EBookCard key={book.id} book={book} />
          ))}
        </div>
      ) : (
        <div className="text-center py-12">
          <h3 className="text-lg font-medium mb-2">No books found</h3>
          <p className="text-muted-foreground mb-4">
          Try adjusting your search or filter criteria
          </p>
          <Button 
            variant="outline" 
            onClick={() => {
              setSearchQuery("");
              setSelectedCategories([]);
            }}
          >
            Reset filters
          </Button>
        </div>
      )}
    </div>
  );
};

export default EBookGrid;
