import { useState } from 'react';
import { User } from './types';
import { auth } from '@/integrations/database/auth';

export const useUserProfile = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserProfile = async (userId: string) => {
    try {
      console.log("Fetching user profile for ID:", userId);
      setIsLoading(true);
      
      const token = localStorage.getItem('auth-token');
      if (!token) {
        throw new Error('No auth token found');
      }

      const userData = await auth.verifyToken(token);

      if (userData) {
        console.log("Profile data received:", userData);
        setUser(userData);
      } else {
        console.log("No profile data found");
        setUser(null);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setUser(null);
    } finally {
      console.log("Finished fetching user profile");
      setIsLoading(false);
    }
  };

  const setUserProfile = (user: User | null) => {
    console.log("Setting user profile:", user);
    setUser(user);
  };

  const setLoading = (loading: boolean) => {
    console.log("Setting loading state:", loading);
    setIsLoading(loading);
  };

  return {
    user,
    setUser: setUserProfile,
    isLoading,
    setIsLoading: setLoading,
    fetchUserProfile
  };
};
