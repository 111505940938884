import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import UserRow from "./UserRow";
import { ManagedUser } from "../UserManagement";

interface UserTableProps {
  users: ManagedUser[];
  loading: boolean;
  onToggleSubscription: (userId: string, newStatus: boolean) => Promise<void>;
}

const UserTable = ({ users, loading, onToggleSubscription }: UserTableProps) => {
  if (loading) {
    return (
      <div className="rounded-md border">
        <div className="p-8 text-center">
          <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full mx-auto mb-4"></div>
          <p>Loading users...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Role</TableHead>
            <TableHead>Subscription</TableHead>
            <TableHead>Created</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} className="text-center">
                No users found
              </TableCell>
            </TableRow>
          ) : (
            users.map((user) => (
              <UserRow 
                key={user.id} 
                user={user} 
                onToggleSubscription={onToggleSubscription} 
              />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default UserTable;
