import MainLayout from "@/layouts/MainLayout";

const TermsOfService = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-8 text-center">Terms of Service</h1>
        <div className="max-w-3xl mx-auto text-lg text-muted-foreground space-y-6">
          <p>
            <strong>Last updated: {new Date().toLocaleDateString()}</strong>
          </p>
          <p>
            Welcome to Our Healthy Space. These Terms of Service govern your use of our website and services. By accessing or using the service, you agree to be bound by these Terms.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-3">Use of Our Service</h2>
          <p>
            You agree to use our service only for lawful purposes and in accordance with these Terms. You agree not to use the service:
            {/* List prohibited uses */}
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-3">Intellectual Property</h2>
          <p>
            The Service and its original content, features, and functionality are and will remain the exclusive property of Our Healthy Space and its licensors.
          </p>
          {/* Add more sections as needed: Accounts, Termination, Disclaimer, Limitation of Liability, Governing Law, Changes, Contact Us, etc. */}
          <p className="mt-8">
            Please review these terms carefully. If you do not agree with the terms, please do not use the service.
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default TermsOfService; 