import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { api } from "@/integrations/database/auth";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";
import UserTable from "./user/UserTable";

export interface ManagedUser {
  id: string;
  email: string;
  name: string | null;
  role: string;
  subscribed: boolean;
  createdAt: string;
}

const UserManagement = () => {
  const { toast } = useToast();
  const [users, setUsers] = useState<ManagedUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchUsers = async () => {
    try {
      setIsRefreshing(true);
      setLoading(true);
      
      const response = await api.get<ManagedUser[]>('/users');
      setUsers(response.data || []);
      
    } catch (error) {
      console.error("Error fetching users:", error);
      toast({
        title: "Error",
        description: "Failed to load users from the server.",
        variant: "destructive",
      });
      setUsers([]);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const toggleUserSubscription = async (userId: string, newStatus: boolean) => {
    try {
      const response = await api.patch<ManagedUser>(`/users/${userId}/subscription`, {
         subscribed: newStatus 
      });

      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, subscribed: response.data.subscribed } : user
        )
      );

      toast({
        title: "Success",
        description: `User subscription ${newStatus ? "activated" : "deactivated"}`,
      });
    } catch (error) {
      console.error("Error updating subscription:", error);
      toast({
        title: "Error",
        description: "Failed to update subscription status",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">User Management</h2>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={fetchUsers}
            disabled={isRefreshing}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isRefreshing ? "animate-spin" : ""}`} />
            Refresh
          </Button>
        </div>
      </div>

      <UserTable 
        users={users} 
        loading={loading} 
        onToggleSubscription={toggleUserSubscription} 
      />
    </div>
  );
};

export default UserManagement;
