
import { toast as sonnerToast } from "sonner";

export interface ToastProps {
  id?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  variant?: "default" | "destructive";
  duration?: number;
}

export type ToastActionElement = React.ReactNode;

export function toast(props: ToastProps) {
  const { title, description, variant, duration, action } = props;
  
  if (variant === "destructive") {
    return sonnerToast.error(title as string, {
      description,
      duration,
      action,
    });
  }
  
  return sonnerToast(title as string, {
    description,
    duration,
    action,
  });
}

export function useToast() {
  return {
    toast,
    dismiss: sonnerToast.dismiss,
  };
}
