import { FileUp, Upload, Image } from "lucide-react";

interface FileUploadFieldProps {
  file: File | null;
  coverFile: File | null;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCoverChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileUploadField = ({ file, coverFile, onFileChange, onCoverChange }: FileUploadFieldProps) => {
  return (
    <div className="space-y-4">
      {/* PDF Upload */}
      <div className="border border-input rounded-md">
        {file ? (
          <div className="p-4">
            <div className="flex items-center gap-2">
              <FileUp className="h-5 w-5 text-primary" />
              <span className="font-medium">{file.name}</span>
              <span className="text-muted-foreground text-sm">
                ({(file.size / 1024 / 1024).toFixed(2)} MB)
              </span>
            </div>
          </div>
        ) : (
          <label htmlFor="pdf-upload" className="flex flex-col items-center justify-center p-6 cursor-pointer">
            <Upload className="h-10 w-10 text-muted-foreground mb-2" />
            <p className="text-sm font-medium">Click to upload PDF</p>
            <p className="text-xs text-muted-foreground mt-1">PDF files only (max 300MB)</p>
          </label>
        )}
        <input 
          id="pdf-upload"
          type="file" 
          accept="application/pdf" 
          onChange={onFileChange}
          className="hidden"
        />
      </div>

      {/* Cover Image Upload (Optional) */}
      <div className="border border-input rounded-md">
        {coverFile ? (
          <div className="p-4">
            <div className="flex items-center gap-2">
              <Image className="h-5 w-5 text-primary" />
              <span className="font-medium">{coverFile.name}</span>
              <span className="text-muted-foreground text-sm">
                ({(coverFile.size / 1024 / 1024).toFixed(2)} MB)
              </span>
            </div>
            {/* Preview da imagem */}
            <div className="mt-2">
              <img
                src={URL.createObjectURL(coverFile)}
                alt="Cover preview"
                className="max-h-32 rounded-md"
              />
            </div>
          </div>
        ) : (
          <label htmlFor="cover-upload" className="flex flex-col items-center justify-center p-6 cursor-pointer">
            <Image className="h-10 w-10 text-muted-foreground mb-2" />
            <p className="text-sm font-medium">Click to upload cover image (optional)</p>
            <p className="text-xs text-muted-foreground mt-1">If not provided, will use first page of PDF</p>
            <p className="text-xs text-muted-foreground">Image files only (max 5MB)</p>
          </label>
        )}
        <input 
          id="cover-upload"
          type="file" 
          accept="image/*" 
          onChange={onCoverChange}
          className="hidden"
        />
      </div>
    </div>
  );
};

export default FileUploadField;
