import MainLayout from "@/layouts/MainLayout";
import EBookGrid from "@/components/ebooks/EBookGrid";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Database, AlertCircle, Loader2 } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useAuth } from "@/contexts/AuthContext";
import { useEffect, useState } from "react";
import { toast } from "@/hooks/use-toast";
import { EBook } from "@/components/ebooks/EBookCard";
import { api } from "@/integrations/database/auth";

// Interface para Categoria (pode vir de um arquivo de tipos)
interface Category {
  id: string;
  name: string;
}

// Interface CORRETA para a resposta da API
interface ApiBookResponse {
  id: string;
  title: string;
  author: string;
  description: string | null;
  coverImageUrl: string | null; // Corrigido: A API envia coverImageUrl, não coverUrl
  filePath: string; // Corrigido: A API envia filePath, não fileUrl
  isPremium: boolean;
  // category: string | null; // Removido
  Category: Category[]; // <<< CORRIGIDO: Nome da propriedade retornada pelo Prisma com include
  totalPages?: number | null; // Ajustado para null
  isFavoritedByUser?: boolean; // A API pode enviar isso também
  fileType: string; // <<< Adicionado fileType
}

// --- DEFINIR TIPO MAIS ESPECÍFICO --- 
// Estende EBook, mas garante que categories é sempre um array
interface EBookWithCategories extends Omit<EBook, 'categories'> { // Omitir categories opcional
  categories: Category[]; // Adicionar categories obrigatório
}
// --- FIM DA DEFINIÇÃO ---

const Library = () => {
  const { isAdmin, user } = useAuth();
  // MODIFICADO: Usar o tipo mais específico para o estado
  const [books, setBooks] = useState<EBookWithCategories[]>([]); 
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        setLoading(true);
        console.log("Fetching books from API...");
        
        const response = await api.get<ApiBookResponse[]>('/books');
        const data = response.data;
        
        console.log("API response:", data);
        
        // DEBUG: Encontrar livro específico por ID
        const debugBook = data.find(book => book.id === '59406880-98a0-4326-97d1-8c158e40ff96');
        if (debugBook) {
          console.log('Livro problemático na Library:', debugBook);
        }
        
        if (data && data.length > 0) {
          // MODIFICADO: Usar o tipo mais específico para a variável mapeada
          const convertedBooks: EBookWithCategories[] = data.map(apiBook => {
            // DEBUG: Se este é o livro problemático, log mais detalhado
            const isProblematicBook = apiBook.id === '59406880-98a0-4326-97d1-8c158e40ff96';
            if (isProblematicBook) {
              console.log('Mapeando livro problemático:', {
                original: apiBook,
                coverImageUrl: apiBook.coverImageUrl,
                filePath: apiBook.filePath
              });
            }
            
            // O mapeamento já garante que categories é um array
            return {
              id: apiBook.id,
              title: apiBook.title,
              author: apiBook.author,
              description: apiBook.description,
              coverImageUrl: apiBook.coverImageUrl,
              filePath: apiBook.filePath,
              fileType: apiBook.fileType,
              totalPages: apiBook.totalPages,
              categories: apiBook.Category || [],
              isPremium: apiBook.isPremium,
              isFavoritedByUser: apiBook.isFavoritedByUser
            };
          });
          console.log("Converted books:", convertedBooks);
          
          // DEBUG: Verificar livro convertido específico 
          const convertedDebugBook = convertedBooks.find(book => book.id === '59406880-98a0-4326-97d1-8c158e40ff96');
          if (convertedDebugBook) {
            console.log('Livro problemático após conversão na Library:', convertedDebugBook);
          }
          
          setBooks(convertedBooks);
        } else {
          setBooks([]);
        }
      } catch (error) {
        console.error("Error fetching books:", error);
        toast({
          title: "Error",
          description: "Failed to load books. Please try again later.",
          variant: "destructive",
        });
        setBooks([]);
      } finally {
        setLoading(false);
      }
    };
    
    fetchBooks();
  }, []);

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8">
        {isAdmin() && (
          <Card className="mb-8 bg-accent/5 border border-accent/10">
            <CardHeader>
              <CardTitle className="text-xl flex items-center">
                <Database className="mr-2 h-5 w-5" /> Database Status
              </CardTitle>
              <CardDescription>
                {loading 
                  ? "Loading books from database..." 
                  : `Connected to database (${books.length} books found)`}
              </CardDescription>
            </CardHeader>
            <CardContent>
              {loading ? (
                <div className="flex items-center justify-center py-4">
                  <Loader2 className="h-6 w-6 text-primary animate-spin mr-2" />
                  <span>Loading books...</span>
                </div>
              ) : (
                <p className="text-sm text-muted-foreground">
                  {user 
                    ? "You are logged in. Access books based on your subscription."
                    : "Log in to access premium content and manage your library."}
                </p>
              )}
            </CardContent>
            <CardFooter>
              <p className="text-xs text-muted-foreground">
                You are viewing live data from the database.
              </p>
            </CardFooter>
          </Card>
        )}
        
        {/* Agora a prop 'books' corresponde ao tipo esperado por EBookGrid */}
        <EBookGrid 
          books={books} 
        />
      </div>
    </MainLayout>
  );
};

export default Library;
