import { createContext, useContext, useEffect, ReactNode, useState } from 'react';
import { AuthContextType, User } from './types';
import { useUserProfile } from './useUserProfile';
import { useAuthOperations } from './useAuthOperations';
import { useToast } from '@/hooks/use-toast';
import { auth } from '@/integrations/database/auth';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { user, setUser, isLoading, setIsLoading, fetchUserProfile } = useUserProfile();
  const { login: baseLogin, signup: baseSignup, logout: baseLogout } = useAuthOperations(setIsLoading);
  const [authInitialized, setAuthInitialized] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    let mounted = true;
    
    const initAuth = async () => {
      if (!mounted) return;
      
      try {
        const token = localStorage.getItem('auth-token');
        
        if (token) {
          const userData = await auth.verifyToken(token);
          
          if (userData) {
            setUser(userData);
          } else {
            localStorage.removeItem('auth-token');
            setUser(null);
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error checking auth state:', error);
        if (mounted) {
          toast({
            title: "Initialization Error",
            description: "An error occurred while initializing authentication. Please reload the page.",
            variant: "destructive",
          });
          setUser(null);
          localStorage.removeItem('auth-token');
        }
      } finally {
        if (mounted) {
          setAuthInitialized(true);
          setIsLoading(false);
        }
      }
    };

    initAuth();

    return () => {
      mounted = false;
    };
  }, []);

  const login = async (email: string, password: string) => {
    const response = await baseLogin(email, password);
    if (response.token && response.user) {
      console.log("[AuthProvider] Login successful, saving token and setting user.");
      localStorage.setItem('auth-token', response.token);
      setUser(response.user);
    } else {
      console.error("[AuthProvider] Login response missing token or user:", response);
    }
    return response;
  };

  const signup = async (email: string, password: string, name: string) => {
    const response = await baseSignup(email, password, name);
    if (response.token && response.user) {
      console.log("[AuthProvider] Signup successful, saving token and setting user.");
      localStorage.setItem('auth-token', response.token);
      setUser(response.user);
    } else {
      console.error("[AuthProvider] Signup response missing token or user:", response);
    }
    return response;
  };

  const logout = async () => {
    await baseLogout();
    setUser(null);
  };

  const isAdmin = () => {
    // Aceita tanto 'admin' quanto 'ADMIN' 
    return user?.role?.toLowerCase() === 'admin';
  };

  const isSubscribed = () => {
    return user?.subscribed === true;
  };

  // Só mostra loading quando estiver inicializando a autenticação
  const isLoadingState = isLoading && !authInitialized;

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading: isLoadingState,
        login,
        signup,
        logout,
        isAdmin,
        isSubscribed
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
