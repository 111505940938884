import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ou seu hook de fetch preferido (como useQuery de @tanstack/react-query)
import { Switch } from '@/components/ui/switch'; // Assumindo shadcn/ui Switch
import { Label } from '@/components/ui/label';   // Assumindo shadcn/ui Label
import { toast } from 'sonner'; // Assumindo sonner para notificações

interface Setting {
  id: string;
  key: string;
  value: string; // Vem como string do DB ('true'/'false')
  createdAt: string;
  updatedAt: string;
}

const AdminSettingsPage: React.FC = () => {
  const [settings, setSettings] = useState<Setting[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Mapeamento de chaves para nomes mais amigáveis
  const settingLabels: { [key: string]: string } = {
    feature_progress_premium: 'Recurso de Progresso Premium',
    feature_favorites_premium: 'Recurso de Favoritos Premium',
    // Adicione outras chaves aqui se necessário
  };

  const fetchSettings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // TODO: Configurar axios para enviar token de autenticação (interceptor ou header)
      const response = await axios.get<Setting[]>('/api/settings');
      setSettings(response.data);
    } catch (err: any) {
      console.error("Erro ao buscar configurações:", err);
      setError(err.response?.data?.message || 'Falha ao buscar configurações.');
      toast.error('Falha ao buscar configurações.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSettingChange = async (key: string, newValue: boolean) => {
    // Otimisticamente atualiza a UI (opcional)
    // setSettings(prev => prev.map(s => s.key === key ? { ...s, value: newValue.toString() } : s));

    try {
        // TODO: Configurar axios para enviar token de autenticação
      await axios.put(`/api/settings/${key}`, { value: newValue });
      toast.success(`Configuração '${settingLabels[key] || key}' atualizada!`);
      // Refetch para garantir consistência ou atualizar estado local com resposta
      fetchSettings();
    } catch (err: any) {
      console.error(`Erro ao atualizar configuração ${key}:`, err);
      toast.error(err.response?.data?.message || `Falha ao atualizar '${settingLabels[key] || key}'.`);
      // Reverter atualização otimista se falhar (se implementada)
      fetchSettings(); // Rebusca para obter o estado real
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  if (isLoading) {
    return <div>Carregando configurações...</div>;
  }

  if (error) {
    return <div className="text-red-500">Erro: {error}</div>;
  }

  // Filtrar para mostrar apenas as configurações conhecidas (ou todas, se preferir)
  const knownKeys = Object.keys(settingLabels);
  const displaySettings = settings.filter(s => knownKeys.includes(s.key));

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Configurações de Administrador</h1>
      <div className="space-y-4">
        {displaySettings.length > 0 ? (
          displaySettings.map((setting) => (
            <div key={setting.key} className="flex items-center justify-between p-4 border rounded-md">
              <Label htmlFor={`switch-${setting.key}`} className="text-lg">
                {settingLabels[setting.key] || setting.key}
              </Label>
              <Switch
                id={`switch-${setting.key}`}
                checked={setting.value === 'true'}
                onCheckedChange={(checked) => handleSettingChange(setting.key, checked)}
              />
            </div>
          ))
        ) : (
          <p>Nenhuma configuração encontrada ou disponível.</p>
        )}
        {/* TODO: Adicionar botão para recarregar? */}
      </div>
    </div>
  );
};

export default AdminSettingsPage; 