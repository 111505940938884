import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useEBookUpload } from "@/hooks/use-ebook-upload";
import FileUploadField from "./ebook/FileUploadField";
import BookDetailsFields from "./ebook/BookDetailsFields";

const EBookUploadForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const {
    file,
    coverFile,
    bookData,
    isUploading,
    allCategories,
    isLoadingCategories,
    setBookData,
    handleFileChange,
    handleCoverChange,
    handleInputChange,
    handleCategoryChange,
    handleUpload
  } = useEBookUpload({ onSuccess });

  const handlePremiumChange = (checked: boolean) => {
    setBookData(prev => ({ ...prev, isPremium: checked }));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Upload New eBook (PDF)</CardTitle>
      </CardHeader>
      <form onSubmit={handleUpload}>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <FileUploadField 
              file={file} 
              coverFile={coverFile}
              onFileChange={handleFileChange} 
              onCoverChange={handleCoverChange}
            />
          </div>

          <BookDetailsFields 
            bookData={bookData}
            allCategories={allCategories}
            isLoadingCategories={isLoadingCategories}
            onInputChange={handleInputChange}
            onPremiumChange={handlePremiumChange}
            onCategoryChange={handleCategoryChange}
          />
        </CardContent>
        <CardFooter>
          <Button type="submit" disabled={isUploading}>
            {isUploading ? "Uploading..." : "Upload eBook"}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default EBookUploadForm;
