import React, { useState, useEffect, useCallback } from 'react';
import { api } from '@/integrations/database/auth';
import BookDetailsFields from '@/components/admin/ebook/BookDetailsFields';
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from 'lucide-react';

// Interface para Categoria (igual à usada no upload)
interface Category {
  id: string;
  name: string;
}

// Interface para o livro recebido como prop
// Idealmente, viria de um tipo compartilhado ou gerado pelo Prisma
interface BookToEdit {
  id: string;
  title: string;
  author: string;
  description: string | null;
  categories: Category[]; 
  isPremium: boolean; 
  coverImageUrl: string | null;
  filePath: string;
  fileType?: string;
  totalPages?: number | null;
}

interface EditBookFormProps {
  bookToEdit: BookToEdit; // Usar a interface atualizada
  onSuccess: (updatedBookData: any) => void; // O tipo exato de retorno da API pode variar
  onCancel: () => void;
}

export const EditBookForm: React.FC<EditBookFormProps> = ({ bookToEdit, onSuccess, onCancel }) => {
  // State for form fields
  const [title, setTitle] = useState(bookToEdit.title);
  const [author, setAuthor] = useState(bookToEdit.author);
  const [description, setDescription] = useState(bookToEdit.description || '');
  const [isPremium, setIsPremium] = useState(bookToEdit.isPremium);
  // Estado para IDs de categorias selecionadas
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>(() => 
    bookToEdit.categories ? bookToEdit.categories.map(cat => cat.id) : []
  );

  // Estado para todas as categorias e carregamento
  const [allCategories, setAllCategories] = useState<Category[]>([]); 
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  
  // State for optional new files
  const [newPdfFile, setNewPdfFile] = useState<File | null>(null);
  const [newCoverFile, setNewCoverFile] = useState<File | null>(null);
  
  // State for UI feedback
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  // Buscar todas as categorias ao montar
  const fetchCategories = useCallback(async () => {
    setIsLoadingCategories(true);
    try {
      const response = await api.get<Category[]>('/categories');
      setAllCategories(response.data);
    } catch (error) {
      console.error("Erro ao buscar categorias no formulário de edição:", error);
      // Opcional: mostrar toast de erro
    } finally {
      setIsLoadingCategories(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // Reset form if bookToEdit changes
  useEffect(() => {
    setTitle(bookToEdit.title);
    setAuthor(bookToEdit.author);
    setDescription(bookToEdit.description || '');
    setIsPremium(bookToEdit.isPremium);
    setSelectedCategoryIds(bookToEdit.categories ? bookToEdit.categories.map(cat => cat.id) : []);
    setNewPdfFile(null);
    setNewCoverFile(null);
    setError(null);
    setIsUpdating(false);
  }, [bookToEdit]);

  const handlePdfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setNewPdfFile(file);
      setError(null);
    } else if (file) {
      setError('Please select a valid PDF file');
      setNewPdfFile(null);
    }
  };

  const handleCoverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setNewCoverFile(file);
      setError(null);
    } else if (file) {
      setError('Please select a valid image file');
      setNewCoverFile(null);
    }
  };

  // Handler para input/textarea (usado por BookDetailsFields)
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'title') setTitle(value);
    else if (name === 'author') setAuthor(value);
    else if (name === 'description') setDescription(value);
  };

  // Handler para mudança de seleção de categorias (usado por BookDetailsFields)
  const handleCategoryChange = (selectedIds: string[]) => {
    setSelectedCategoryIds(selectedIds);
  };

  // Handler para mudança de isPremium (usado por BookDetailsFields)
  const handlePremiumChange = (checked: boolean) => {
    setIsPremium(checked);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUpdating(true);
    setError(null);

    try {
      const formData = new FormData();
      
      // Campos obrigatórios
      formData.append('title', title);
      formData.append('author', author);
      formData.append('description', description);
      formData.append('is_premium', isPremium.toString()); 
      
      // Adiciona categoryIds ao FormData
      selectedCategoryIds.forEach(id => {
        formData.append('categoryIds[]', id);
      });
      
      // Append files if selected
      if (newPdfFile) {
        formData.append('file', newPdfFile);
      }
      if (newCoverFile) {
        formData.append('cover', newCoverFile);
        console.log("[EditBookForm] Appending cover file to FormData:", newCoverFile.name, newCoverFile.size);
      }

      console.log("FormData entries before sending PUT:");
      for (let [key, value] of formData.entries()) { 
        console.log(`  ${key}:`, value instanceof File ? value.name : value); 
      }

      // Send PUT request
      const response = await api.put(`/books/${bookToEdit.id}`, formData, {
        // >>> NUNCA defina Content-Type manualmente para FormData com Axios <<<
        // headers: {
        //   'Content-Type': 'multipart/form-data' // <<< REMOVER/COMENTAR ESTA LINHA
        // },
      });

      toast({ title: "Success", description: "Book updated successfully!" });
      onSuccess(response.data); // Passar os dados atualizados

    } catch (err: any) {
      console.error('Update error:', err);
      const errorMsg = err.response?.data?.error || err.message || 'Failed to update book';
      setError(errorMsg);
      toast({
        title: "Error Updating Book",
        description: errorMsg,
        variant: "destructive",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Construct the full URL for displaying the current cover
  const BACKEND_URL = import.meta.env.VITE_BACKEND_URL || 'http://localhost:3001';
  const currentCoverDisplayUrl = bookToEdit.coverImageUrl ? `${BACKEND_URL}${bookToEdit.coverImageUrl}` : undefined;

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* File Inputs for Optional Replacement */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Replace PDF File (Optional)
        </label>
        <input
          type="file"
          accept=".pdf"
          onChange={handlePdfChange}
          className="mt-1 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-md file:border-0
            file:text-sm file:font-semibold
            file:bg-blue-50 file:text-blue-700
            hover:file:bg-blue-100"
        />
         {newPdfFile && <p className="text-xs text-muted-foreground mt-1">Selected: {newPdfFile.name}</p>}
         <p className="text-xs text-muted-foreground mt-1">Current: {bookToEdit.filePath?.split('/').pop() || 'N/A'}</p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Replace Cover Image (Optional)
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={handleCoverChange}
          className="mt-1 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-md file:border-0
            file:text-sm file:font-semibold
            file:bg-blue-50 file:text-blue-700
            hover:file:bg-blue-100"
        />
        {newCoverFile && <p className="text-xs text-muted-foreground mt-1">Selected: {newCoverFile.name}</p>}
        {/* Display current cover image using the full URL */}
        {currentCoverDisplayUrl && (
          <img 
             src={currentCoverDisplayUrl} 
             alt="Current cover" 
             className="mt-2 h-16 w-auto object-contain border rounded"
             onError={(e) => (e.currentTarget.style.display = 'none')} // Hide if image fails to load
           />
         )}
      </div>

      {/* Passar as props corretas para BookDetailsFields */}
      <BookDetailsFields
        bookData={{
          title,
          author,
          description,
          isPremium,
          selectedCategoryIds // Passar IDs selecionados
        }}
        allCategories={allCategories} // Passar todas as categorias
        isLoadingCategories={isLoadingCategories} // Passar status de carregamento
        onInputChange={handleInputChange} // Passar handler correto
        onPremiumChange={handlePremiumChange} // Passar handler correto
        onCategoryChange={handleCategoryChange} // Passar handler correto
      />

      {error && (
        <p className="text-sm text-red-600">Error: {error}</p>
      )}

      <div className="flex justify-end space-x-3">
        <Button type="button" variant="outline" onClick={onCancel} disabled={isUpdating}>
          Cancel
        </Button>
        <Button type="submit" disabled={isUpdating} className="btn-hover">
          {isUpdating ? (
            <><Loader2 className="mr-2 h-4 w-4 animate-spin" /> Updating...</>
          ) : (
            'Update Book'
          )}
        </Button>
      </div>
    </form>
  );
}; 