import { useState, useEffect } from 'react';
import { api } from '@/integrations/database/auth';
import { EBook } from '@/components/ebooks/EBookCard'; // Remover Category da importação

// Assume que Category é definido globalmente ou em outro tipo importado
interface Category {
  id: string;
  name: string;
}

// Define a type for the API response (matching the backend Book model)
interface ApiBookResponse {
  id: string;
  title: string;
  author: string;
  description: string | null;
  coverImageUrl: string | null; // From backend
  filePath: string; // From backend
  totalPages?: number | null;
  isPremium: boolean;
  categories?: Category[]; // Assuming categories are included
  isFavoritedByUser?: boolean; // Assuming favorite status is included
}

export const useRecentBooks = () => {
  const [recentBooks, setRecentBooks] = useState<EBook[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchRecentBooks = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Fetch data using the API response type
        const response = await api.get<ApiBookResponse[]>('/books/recents');
        console.log("[useRecentBooks] API response:", response.data); // Log API response
        
        // Map the API response to the EBook type used by the frontend
        const mappedBooks: EBook[] = response.data.map(apiBook => ({
          id: apiBook.id,
          title: apiBook.title,
          author: apiBook.author,
          description: apiBook.description,
          coverImageUrl: apiBook.coverImageUrl,
          filePath: apiBook.filePath,
          totalPages: apiBook.totalPages,
          isPremium: apiBook.isPremium,
          categories: apiBook.categories || [],
          isFavoritedByUser: apiBook.isFavoritedByUser,
        }));
        
        console.log("[useRecentBooks] Mapped books:", mappedBooks); // Log mapped books
        setRecentBooks(mappedBooks); // Set the mapped books

      } catch (err) {
        console.error("Error fetching recent books:", err);
        setError(err instanceof Error ? err : new Error('Failed to fetch recent books'));
        setRecentBooks([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentBooks();
    
    // TODO: Consider adding a dependency array or a refresh function 
    // if recent books should update automatically without a full page reload.
    // For now, it fetches only on component mount.
  }, []); // Empty dependency array means fetch once on mount

  return { recentBooks, isLoading, error };
}; 