import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { BookOpen, ArrowRight, Check } from "lucide-react";

const LandingPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero Section */}
      <section className="flex-grow flex items-center justify-center bg-gradient-to-b from-background to-accent/50 py-20 px-4 text-center">
        <div className="max-w-4xl">
          <BookOpen className="h-16 w-16 text-primary mx-auto mb-6" />
          <h1 className="text-4xl md:text-6xl font-bold tracking-tight mb-4">
            Welcome to OurHealthySpace
          </h1>
          <p className="text-lg md:text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
            Discover a world of knowledge. Access a vast library of eBooks, track your reading progress, and join a community of learners.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button size="lg" asChild className="btn-hover">
              <Link to="/signup">
                Get Started Free <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </Button>
            <Button size="lg" variant="outline" asChild>
              <Link to="/library">Explore Library</Link>
            </Button>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 lg:py-24 bg-background px-4">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Us?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Feature 1 */}
            <div className="text-center p-6 border rounded-lg shadow-sm hover:shadow-md transition-shadow">
              <BookOpen className="h-10 w-10 text-primary mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Extensive Library</h3>
              <p className="text-muted-foreground">
                Access thousands of eBooks across various genres and topics.
              </p>
            </div>
            {/* Feature 2 */}
            <div className="text-center p-6 border rounded-lg shadow-sm hover:shadow-md transition-shadow">
              <Check className="h-10 w-10 text-green-500 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Progress Tracking</h3>
              <p className="text-muted-foreground">
                Never lose your place. Save your reading progress effortlessly (Premium).
              </p>
            </div>
            {/* Feature 3 */}
            <div className="text-center p-6 border rounded-lg shadow-sm hover:shadow-md transition-shadow">
              <Users className="h-10 w-10 text-blue-500 mx-auto mb-4" /> {/* Assuming Users icon exists */}
              <h3 className="text-xl font-semibold mb-2">Community Focused</h3>
              <p className="text-muted-foreground">
                Connect with fellow readers and share your insights. (Coming Soon)
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-16 lg:py-24 bg-primary/5 px-4">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Start Reading?</h2>
          <p className="text-lg text-muted-foreground mb-8">
            Sign up today and unlock your first few chapters for free!
          </p>
          <Button size="lg" asChild className="btn-hover">
            <Link to="/signup">
              Sign Up Now <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </Button>
        </div>
      </section>

      {/* Footer Placeholder - Assuming Footer is handled by App.tsx or MainLayout */}
      {/* <footer className="bg-muted text-muted-foreground py-6 text-center text-sm">
        © {new Date().getFullYear()} OurHealthySpace. All rights reserved.
      </footer> */}
    </div>
  );
};

// Helper icon component if Users doesn't exist in lucide-react or your setup
const Users = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
    <circle cx="9" cy="7" r="4" />
    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
  </svg>
);


export default LandingPage; 