import { ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Loader2 } from "lucide-react";

interface AuthGuardProps {
  children: ReactNode;
  requireAdmin?: boolean;
}

const AuthGuard = ({ children, requireAdmin = false }: AuthGuardProps) => {
  const { user, isLoading, isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        // Store the attempted URL for redirect after login
        navigate("/login", { 
          state: { from: location.pathname },
          replace: true 
        });
      } else if (requireAdmin && !isAdmin()) {
        navigate("/dashboard", { replace: true });
      }
    }
  }, [user, isLoading, isAdmin, navigate, requireAdmin, location.pathname]);
  
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <p className="text-sm text-muted-foreground">Carregando...</p>
        </div>
      </div>
    );
  }
  
  if (!user || (requireAdmin && !isAdmin())) {
    return null;
  }
  
  return <>{children}</>;
};

export default AuthGuard;
