import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { Header } from './components/layout/Header';
import { BottomNav } from './components/layout/BottomNav';
import Footer from './components/layout/Footer';
import AuthGuard from "@/components/guards/AuthGuard";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

// Log da versão ao iniciar
console.log(`[App Version] Running version: ${import.meta.env.VITE_APP_VERSION || 'N/A'}`);

function App() {
  const location = useLocation();
  const hideManagePaymentInFooter = location.pathname !== '/dashboard';

  return (
    <QueryClientProvider client={queryClient}> 
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-1 container py-8 pb-20 md:pb-8"> 
          <Outlet />
        </main>
        <BottomNav />
        <Footer hideManagePayment={hideManagePaymentInFooter} />
      </div>
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
