import React from 'react';
import CategoryManager from '@/components/admin/CategoryManager.tsx';

const AdminCategoriesPage: React.FC = () => {
  return (
    <div>
      <h1>Painel Administrativo - Categorias</h1>
      <CategoryManager />
    </div>
  );
};

export default AdminCategoriesPage; 