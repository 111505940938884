import { NavLink, Link } from 'react-router-dom';
import { Home, Library, Tag, LayoutGrid, User } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { cn } from '@/lib/utils';

export const BottomNav = () => {
  const { user } = useAuth();

  // Define items always visible
  const baseNavItems = [
    { label: 'Home', href: '/', icon: Home },
    { label: 'Library', href: '/library', icon: Library },
    // { label: 'Pricing', href: '/pricing', icon: Tag },
  ];

  // Add dashboard if user is logged in
  const loggedInNavItems = user
    ? [...baseNavItems, { label: 'Dashboard', href: '/dashboard', icon: LayoutGrid }]
    : baseNavItems;

  // Determine the last item based on auth state
  const lastItem = user
    ? { label: 'Profile', href: '/profile', icon: User } // Example: Link to a profile page
    : { label: 'Login', href: '/login', icon: User }; // Link to login if not logged in

  const allNavItems = [...loggedInNavItems, lastItem];

  return (
    <nav className="fixed bottom-0 left-0 right-0 z-50 border-t bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 h-16 md:hidden">
      <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
        {/* Map over the first 3 (or 4 if logged in) items */} 
        {loggedInNavItems.map((item) => (
          <NavLink
            key={item.href}
            to={item.href}
            end // Use 'end' prop for Home to avoid matching other routes
            className={({ isActive }) =>
              cn(
                "inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group",
                isActive ? "text-primary dark:text-primary" : "text-gray-500 dark:text-gray-400"
              )
            }
          >
            <item.icon className="w-5 h-5 mb-1 transition duration-75 group-hover:text-primary dark:group-hover:text-primary" />
            <span className="text-xs sm:text-sm">{item.label}</span>
          </NavLink>
        ))}
        {/* Separate link for the last item (Profile/Login) */} 
        <Link
          to={lastItem.href}
          className={cn(
            "inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group",
             // Add active state logic if profile page exists and needs highlighting
            "text-gray-500 dark:text-gray-400" 
          )}
        >
          <lastItem.icon className="w-5 h-5 mb-1 transition duration-75 group-hover:text-primary dark:group-hover:text-primary" />
          <span className="text-xs sm:text-sm">{lastItem.label}</span>
        </Link>
      </div>
    </nav>
  );
}; 