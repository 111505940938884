import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { api } from '@/integrations/database/auth';

// Interface para as configurações esperadas
export interface AppSettings {
  feature_progress_premium?: boolean;
  feature_favorites_premium?: boolean;
  // Adicionar outras configurações conforme necessário
}

interface SettingsContextType {
  settings: AppSettings;
  isLoading: boolean;
  error: Error | null;
}

const defaultSettings: AppSettings = {
  // Definir padrões caso a API falhe ou não retorne as chaves
  feature_progress_premium: true, // Padrão: requer premium
  feature_favorites_premium: true, // Padrão: requer premium
};

const SettingsContext = createContext<SettingsContextType>({ 
  settings: defaultSettings,
  isLoading: true,
  error: null,
});

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useState<AppSettings>(defaultSettings);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      setError(null);
      console.log("[SettingsContext] Fetching app settings...");
      try {
        const response = await api.get<AppSettings>('/settings');
        // Mesclar com padrões para garantir que todas as chaves existam
        const fetchedSettings = { ...defaultSettings, ...(response.data || {}) };
        setSettings(fetchedSettings);
        console.log("[SettingsContext] Settings loaded:", fetchedSettings);
      } catch (err) {
        console.error("[SettingsContext] Error fetching settings:", err);
        setError(err instanceof Error ? err : new Error('Failed to load settings'));
        setSettings(defaultSettings); // Manter padrões em caso de erro
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []); // Busca apenas uma vez na montagem

  return (
    <SettingsContext.Provider value={{ settings, isLoading, error }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}; 