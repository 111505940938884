import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import MainLayout from "@/layouts/MainLayout";

const AdminLayout: React.FC = () => {
  const location = useLocation();

  const getLinkClass = (path: string) => {
    return location.pathname === path 
      ? 'px-4 py-2 rounded bg-primary text-primary-foreground' 
      : 'px-4 py-2 rounded hover:bg-muted';
  };

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold mb-6">Painel Administrativo</h1>
        <nav className="mb-8 flex space-x-2 border-b pb-2">
          <Link to="/admin" className={getLinkClass('/admin')}>Dashboard</Link>
          <Link to="/admin/categories" className={getLinkClass('/admin/categories')}>Categorias</Link>
          {/* Adicione links para outras seções admin aqui */}
        </nav>
        
        {/* O conteúdo da rota filha será renderizado aqui */}
        <Outlet /> 
      </div>
    </MainLayout>
  );
};

export default AdminLayout; 