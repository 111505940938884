import { useEffect, useState } from "react";
import { FileText, Trash2, Edit } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { Badge } from "@/components/ui/badge";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { api } from "@/integrations/database/auth";
import { EditBookForm } from "./EditBookForm";

interface Category {
  id: string;
  name: string;
}

interface Book {
  id: string;
  title: string;
  author: string;
  description: string | null;
  categories: Category[];
  coverImageUrl: string | null;
  filePath: string;
  fileType: string;
  isPremium: boolean;
  createdAt: string;
  updatedAt: string;
  totalPages?: number | null;
}

interface EBooksManagementProps {
  refreshTrigger?: number;
}

const EBooksManagement = ({ refreshTrigger = 0 }: EBooksManagementProps) => {
  const [books, setBooks] = useState<Book[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deletingBook, setDeletingBook] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editingBook, setEditingBook] = useState<Book | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    fetchBooks();
  }, [refreshTrigger]);

  const fetchBooks = async () => {
    try {
      setIsLoading(true);
      const response = await api.get<Book[]>('/books');
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching books:', error);
      toast({
        title: "Error",
        description: "Failed to fetch books",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (bookId: string) => {
    setDeletingBook(bookId);
    setDeleteDialogOpen(true);
  };
  
  const handleEditClick = (book: Book) => {
    setEditingBook(book);
  };

  const handleEditSuccess = (updatedBookData: any) => {
    console.log("[handleEditSuccess] Received updatedBookData from API:", JSON.stringify(updatedBookData));
    
    const mappedBook: Book = {
      ...updatedBookData,
      coverImageUrl: updatedBookData.coverImageUrl,
      filePath: updatedBookData.filePath,
      fileType: updatedBookData.fileType,
      categories: updatedBookData.Category || [],
    };
    
    console.log("[handleEditSuccess] Mapped book before setting state:", JSON.stringify(mappedBook));
    
    setBooks(prevBooks => 
      prevBooks.map(book => book.id === mappedBook.id ? mappedBook : book)
    );
    setEditingBook(null);
  };

  const handleEditCancel = () => {
    setEditingBook(null);
  };

  const handleDeleteBook = async () => {
    if (!deletingBook) return;
    
    try {
      await api.delete(`/books/${deletingBook}`);
      
      setBooks(books.filter(book => book.id !== deletingBook));
      
      toast({
        title: "Success",
        description: "Book deleted successfully",
      });
      
    } catch (error: any) {
      console.error("Error deleting book:", error);
      toast({
        title: "Error",
        description: error.response?.data?.error || "Failed to delete book",
        variant: "destructive",
      });
    } finally {
      setDeletingBook(null);
      setDeleteDialogOpen(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Title</TableHead>
            <TableHead>Author</TableHead>
            <TableHead>Categories</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>File</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {books.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} className="text-center">
                No books found
              </TableCell>
            </TableRow>
          ) : (
            books.map((book) => (
              <TableRow key={book.id}>
                <TableCell className="font-medium">{book.title}</TableCell>
                <TableCell>{book.author}</TableCell>
                <TableCell>
                  {book.categories && book.categories.length > 0 ? (
                    book.categories.map(cat => cat.name).join(', ')
                  ) : (
                    <span className="text-muted-foreground italic">Uncategorized</span>
                  )}
                </TableCell>
                <TableCell>
                  {book.isPremium ? (
                    <Badge variant="default" className="bg-amber-500">Premium</Badge>
                  ) : (
                    <Badge variant="outline">Free</Badge>
                  )}
                </TableCell>
                <TableCell>
                  {book.filePath ? (
                    <a
                      href={book.filePath}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-blue-600 hover:text-blue-800"
                    >
                      <FileText className="h-4 w-4 mr-1" />
                      <span className="text-xs">View</span>
                    </a>
                  ) : (
                    <span className="text-gray-400 text-xs">No file</span>
                  )}
                </TableCell>
                <TableCell className="text-right">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleEditClick(book)}
                    className="mr-2"
                  >
                    <Edit className="h-4 w-4 text-blue-500" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDeleteClick(book.id)}
                  >
                    <Trash2 className="h-4 w-4 text-red-500" />
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the book
              and its associated file.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteBook}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Dialog open={!!editingBook} onOpenChange={(isOpen) => !isOpen && setEditingBook(null)}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Edit Book Details</DialogTitle>
            <DialogDescription>
               Update the details for {editingBook ? `'${editingBook.title}'` : 'the selected book'}. 
               Upload new files only if you want to replace the current ones.
            </DialogDescription>
          </DialogHeader>
          {editingBook && (
            <EditBookForm 
              bookToEdit={editingBook}
              onSuccess={handleEditSuccess}
              onCancel={handleEditCancel}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EBooksManagement;
