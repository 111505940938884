import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import validator from 'validator';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import SocialLoginButtons from "./SocialLoginButtons";
import AuthFooter from "./AuthFooter";
import { Input } from "../ui/input";
import { Mail, Lock, User } from "lucide-react";

// Regex for validating name (allows letters, spaces, hyphens, apostrophes, accented characters) - Same as backend
const nameRegex = /^[\p{L}\s'-]+$/u;

type AuthFormProps = {
  type: "login" | "signup";
  onSubmit: (email: string, password: string, name?: string) => Promise<void>;
  isLoading?: boolean;
};

// Define schemas for login and signup with English messages and stricter validation
const loginSchema = z.object({
  email: z.string()
    .min(1, { message: "Email is required" }) // Ensure not empty
    .refine(validator.isEmail, { // Use validator for stricter check
      message: "Invalid email format",
    }),
  password: z.string().min(6, { message: "Password must be at least 6 characters" }),
});

const signupSchema = loginSchema.extend({
  name: z.string()
    .min(2, { message: "Name must be at least 2 characters" })
    .refine(val => nameRegex.test(val), { // Use regex for validation
      message: "Name can only contain letters, spaces, hyphens, and apostrophes",
    }),
});

// Define the form data type using Zod inference
type LoginFormValues = z.infer<typeof loginSchema>;
type SignupFormValues = z.infer<typeof signupSchema>;

const AuthForm = ({ type, onSubmit, isLoading = false }: AuthFormProps) => {
  const [serverError, setServerError] = useState<string | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  
  // Initialize the form with React Hook Form and onChange validation mode
  const form = useForm<LoginFormValues | SignupFormValues>({
    resolver: zodResolver(type === "login" ? loginSchema : signupSchema),
    defaultValues: type === "login" 
      ? { email: "", password: "" } 
      : { email: "", password: "", name: "" },
    mode: 'onChange', // Validate fields on change
  });

  // Handle form submission
  const handleSubmit = async (values: LoginFormValues | SignupFormValues) => {
    setServerError(null);
    setFormSubmitting(true);
    
    try {
      if (type === "signup" && "name" in values) {
        await onSubmit(values.email, values.password, values.name);
      } else {
        await onSubmit(values.email, values.password);
      }
    } catch (error: unknown) {
      console.error("Form submission error:", error);
      let errorMessage = 'An unexpected error occurred during submission.';
      if (error instanceof Error) {
          errorMessage = error.message;
      }
      setServerError(errorMessage);
    } finally {
      setFormSubmitting(false);
    }
  };

  const buttonIsLoading = isLoading || formSubmitting;

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold tracking-tight">
          {type === "login" ? "Welcome back" : "Create an account"}
        </CardTitle>
        <CardDescription>
          {type === "login" 
            ? "Enter your credentials to access your account" 
            : "Enter your information to create an account"}
        </CardDescription>
      </CardHeader>
      
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            {serverError && (
              <div className="bg-destructive/10 text-destructive rounded-md p-3 mb-4 flex items-start text-sm">
                <div className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5">🚨</div>
                <span>{serverError}</span>
              </div>
            )}
            
            {type === "signup" && (
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <div className="relative">
                      <User className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                      <FormControl>
                        <Input 
                          placeholder="Your full name" 
                          className="pl-10" 
                          {...field} 
                          disabled={buttonIsLoading}
                        />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <div className="relative">
                    <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <FormControl>
                      <Input 
                        placeholder="name@example.com" 
                        type="email" 
                        className="pl-10" 
                        {...field} 
                        disabled={buttonIsLoading}
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center justify-between">
                    <FormLabel>Password</FormLabel>
                    {type === "login" && (
                      <a 
                        href="/forgot-password" 
                        className="text-xs text-primary hover:underline"
                      >
                        Forgot password?
                      </a>
                    )}
                  </div>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <FormControl>
                      <Input 
                        placeholder="••••••••" 
                        type="password" 
                        className="pl-10" 
                        {...field} 
                        disabled={buttonIsLoading}
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <Button 
              type="submit" 
              className="w-full" 
              disabled={buttonIsLoading || !form.formState.isValid}
            >
              {buttonIsLoading ? (
                <span className="flex items-center gap-2">
                  <span className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
                  {type === "login" ? "Signing in..." : "Creating account..."}
                </span>
              ) : (
                type === "login" ? "Sign In" : "Create account"
              )}
            </Button>
          </form>
        </Form>

        {/* Commenting out Social Login Buttons for now */}
        {/* <SocialLoginButtons /> */}
      </CardContent>
      
      <CardFooter className="flex flex-wrap items-center justify-center">
        <AuthFooter type={type} />
      </CardFooter>
    </Card>
  );
};

export default AuthForm;
