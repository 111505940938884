import { useState, useEffect } from 'react';
import { api } from '@/integrations/database/auth';

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
}

export const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    user: null,
    loading: true,
  });

  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    if (token) {
      api.get('/auth/verify')
        .then(response => {
          setAuthState({
            isAuthenticated: true,
            user: response.data.user,
            loading: false,
          });
        })
        .catch(() => {
          localStorage.removeItem('auth-token');
          setAuthState({
            isAuthenticated: false,
            user: null,
            loading: false,
          });
        });
    } else {
      setAuthState(prev => ({ ...prev, loading: false }));
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await api.post('/auth/login', { email, password });
      const { token, user } = response.data;
      localStorage.setItem('auth-token', token);
      setAuthState({
        isAuthenticated: true,
        user,
        loading: false,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const logout = async () => {
    try {
      await api.post('/auth/signout');
      localStorage.removeItem('auth-token');
      setAuthState({
        isAuthenticated: false,
        user: null,
        loading: false,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const isAdmin = () => {
    return authState.user?.role?.toLowerCase() === 'admin';
  };

  return {
    ...authState,
    login,
    logout,
    isAdmin,
  };
}; 