import React, { useState, useEffect, useRef } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { searchPlugin } from '@react-pdf-viewer/search';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Loader2, X, Maximize2, Minimize2, ZoomIn, ZoomOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useIsMobile } from '@/hooks/use-mobile';

// Import only essential styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

interface PDFViewerNewProps {
  url: string;
  title?: string;
  onClose?: () => void;
  initialPage?: number;
  onPageChange?: (pageNumber: number) => void;
}

export const PDFViewerNew: React.FC<PDFViewerNewProps> = ({ 
  url, 
  title, 
  onClose, 
  initialPage = 1,
  onPageChange 
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isUrlVerified, setIsUrlVerified] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const viewerContainerRef = useRef<HTMLDivElement>(null);

  // Initialize plugins
  const searchPluginInstance = searchPlugin();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel, GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;
  const { ZoomIn: ZoomInButton, ZoomOut: ZoomOutButton } = zoomPluginInstance;

  // Handler for page change events from the viewer - Call prop directly
  const handlePageChange = (e: { currentPage: number }) => {
    const pageNum = e.currentPage + 1;
    console.log(`[PDFViewerNew] Viewer event: Page changed to ${pageNum}`);
    if (onPageChange) {
      console.log(`[PDFViewerNew] Calling onPageChange prop with page: ${pageNum}`);
      // Call directly
      onPageChange(pageNum);
    } else {
      console.log(`[PDFViewerNew] onPageChange prop is not defined.`);
    }
  };

  // Ref to store the initialPage prop to avoid re-renders on change
  const initialPageRef = useRef(initialPage);

  // Verify URL accessibility
  useEffect(() => {
    console.log('Verifying PDF URL:', url);
    setIsUrlVerified(false);
    setIsLoading(true);
    setError(null);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
      console.log('URL verification timed out, proceeding anyway');
      setIsUrlVerified(true);
    }, 5000);

    fetch(url, {
      method: 'HEAD',
      signal: controller.signal,
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    })
      .then(response => {
        clearTimeout(timeoutId);
        if (!response.ok) {
          throw new Error(`PDF URL returned status: ${response.status}`);
        }
        console.log('PDF URL is accessible');
        setIsUrlVerified(true);
      })
      .catch(error => {
        clearTimeout(timeoutId);
        if (error.name === 'AbortError') {
          console.log('URL verification was aborted, proceeding anyway');
          setIsUrlVerified(true);
          return;
        }
        console.error('Error verifying PDF URL:', error);
        setError(error);
        toast({
          title: "Warning",
          description: "There was a problem verifying the PDF. Attempting to load anyway.",
          variant: "default",
        });
        setIsUrlVerified(true);
      });

    return () => {
      clearTimeout(timeoutId);
      controller.abort();
    };
  }, [url, toast]);

  const handleDocumentLoad = () => {
    console.log('PDF document loaded successfully');
    setIsLoading(false);
    setError(null);
  };

  // Updated toggleFullscreen function for mobile vs desktop
  const toggleFullscreen = async () => {
    const element = viewerContainerRef.current;

    if (isMobile) {
      // On mobile, just toggle the state controlling CSS
      setIsFullscreen(prev => !prev);
      console.log(`[Mobile Fullscreen] Toggled CSS fullscreen state to: ${!isFullscreen}`);
    } else {
      // On desktop, use the native Fullscreen API
      if (!element) return;
      try {
        if (!document.fullscreenElement) {
          await element.requestFullscreen();
          setIsFullscreen(true);
          console.log("[Desktop Fullscreen] Entered fullscreen via API");
        } else {
          await document.exitFullscreen();
          setIsFullscreen(false);
          console.log("[Desktop Fullscreen] Exited fullscreen via API");
        }
      } catch (error) {
        console.error("Fullscreen API error:", error);
        toast({ title: "Fullscreen Error", description: "Could not toggle fullscreen mode.", variant: "destructive" });
        setIsFullscreen(false); // Ensure state is correct on error
      }
    }
  };

  if (!url) {
    return (
      <div className="flex flex-col items-center justify-center h-[80vh] text-destructive">
        <p className="font-semibold">PDF URL not provided</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-[80vh] text-destructive">
        <p className="font-semibold">Error loading PDF</p>
        <p className="text-sm mt-2">{error.message || 'Could not load the document'}</p>
        <Button 
          variant="outline" 
          className="mt-4" 
          onClick={() => {
            setError(null);
            setIsLoading(true);
          }}
        >
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div 
      ref={viewerContainerRef}
      className={`flex flex-col h-full w-full overflow-hidden bg-background ${isFullscreen ? 'fixed inset-0 z-[100]' : ''}`}
    >
      {/* Top toolbar */}
      <div className="flex items-center justify-between p-2 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-semibold truncate max-w-[200px] sm:max-w-[300px]">{title || 'PDF Viewer'}</h2>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <GoToPreviousPage>
              {(props) => (
                <Button variant="ghost" size="icon" onClick={props.onClick} disabled={props.isDisabled}>
                  <span className="text-xs">Prev</span>
                </Button>
              )}
            </GoToPreviousPage>
            <div className="px-2 text-sm min-w-[60px] text-center">
              <CurrentPageLabel>
                {(props) => <>{props.currentPage + 1} / {props.numberOfPages}</>}
              </CurrentPageLabel>
            </div>
            <GoToNextPage>
              {(props) => (
                <Button variant="ghost" size="icon" onClick={props.onClick} disabled={props.isDisabled}>
                  <span className="text-xs">Next</span>
                </Button>
              )}
            </GoToNextPage>
          </div>
          {!isMobile && (
            <div className="flex items-center gap-1">
              <ZoomOutButton>
                {(props) => (
                  <Button variant="ghost" size="icon" onClick={props.onClick}>
                    <ZoomOut className="h-4 w-4" />
                  </Button>
                )}
              </ZoomOutButton>
              <ZoomInButton>
                {(props) => (
                  <Button variant="ghost" size="icon" onClick={props.onClick}>
                    <ZoomIn className="h-4 w-4" />
                  </Button>
                )}
              </ZoomInButton>
            </div>
          )}
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleFullscreen}
            className="ml-2"
          >
            {isFullscreen ? (
              <Minimize2 className="h-4 w-4" />
            ) : (
              <Maximize2 className="h-4 w-4" />
            )}
          </Button>
          {onClose && (
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="ml-1 text-red-600 hover:bg-red-100 dark:text-red-500 dark:hover:bg-red-900/50"
              aria-label="Close PDF Viewer"
            >
              <X className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>

      {/* PDF Viewer - Adjusted container */}
      <div className="flex-1 relative overflow-y-auto"> {/* Allow vertical scroll */}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-background/80 backdrop-blur-sm z-50">
            <div className="flex flex-col items-center">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="mt-2 text-sm text-muted-foreground">Loading document...</p>
            </div>
          </div>
        )}
        
        {isUrlVerified && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={url}
              plugins={[
                searchPluginInstance,
                zoomPluginInstance,
                pageNavigationPluginInstance
              ]}
              onDocumentLoad={handleDocumentLoad}
              initialPage={initialPageRef.current - 1}
              onPageChange={handlePageChange}
              defaultScale={isMobile ? 0.7 : 1.7}
              theme={{
                theme: 'dark',
              }}
              renderLoader={(percentages: number) => (
                <div className="flex flex-col items-center justify-center h-full">
                  <Loader2 className="h-8 w-8 animate-spin text-primary" />
                  <p className="mt-2 text-sm text-muted-foreground">
                    Loading... {Math.round(percentages)}%
                  </p>
                </div>
              )}
            />
          </Worker>
        )}
      </div>
    </div>
  );
};

export default PDFViewerNew; 