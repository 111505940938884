import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/auth";
import MainLayout from "@/layouts/MainLayout";
import AuthForm from "@/components/auth/AuthForm";
import { useEffect } from "react";
import { useToast } from "@/hooks/use-toast";

const Signup = () => {
  const { signup, user, isLoading } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  
  const handleSignup = async (email: string, password: string, name?: string) => {
    try {
      await signup(email, password, name || '');
      // Auth context will handle state update and redirect
    } catch (error) {
      toast({
        title: "Error creating account",
        description: error instanceof Error ? error.message : "An error occurred while creating your account",
        variant: "destructive",
      });
    }
  };
  
  useEffect(() => {
    if (user && !isLoading) {
      navigate("/dashboard");
    }
  }, [user, isLoading, navigate]);
  
  return (
    <MainLayout>
      <div className="container max-w-6xl mx-auto px-4 py-16 flex flex-col items-center justify-center">
        <AuthForm type="signup" onSubmit={handleSignup} isLoading={isLoading} />
      </div>
    </MainLayout>
  );
};

export default Signup;
