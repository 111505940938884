import React, { useState, useEffect, useCallback } from 'react';
import { api } from '@/integrations/database/auth'; // Importa a instância 'api'

interface Category {
  id: string;
  name: string;
}

const CategoryManager: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch categories
  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get('/categories');
      setCategories(response.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
      // Translate error message
      setError('Failed to load categories.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // Function to add a category
  const handleAddCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newCategoryName.trim()) return;

    setIsLoading(true);
    setError(null);
    try {
      const response = await api.post('/categories', { name: newCategoryName });
      setCategories([...categories, response.data]);
      setNewCategoryName('');
    } catch (err: any) { // Use any or define a proper error type
      console.error('Error adding category:', err);
      // Translate fallback error message
      setError(err.response?.data?.error || 'Failed to add category.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to start editing
  const handleEditClick = (category: Category) => {
    setEditingCategory({ ...category });
  };

  // Function to save edit
  const handleSaveEdit = async () => {
    if (!editingCategory || !editingCategory.name.trim()) return;

    setIsLoading(true);
    setError(null);
    try {
      const response = await api.put(`/categories/${editingCategory.id}`, { name: editingCategory.name });
      setCategories(categories.map(cat => cat.id === editingCategory.id ? response.data : cat));
      setEditingCategory(null);
    } catch (err: any) {
      console.error('Error updating category:', err);
      setError(err.response?.data?.error || 'Failed to update category.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to cancel edit
  const handleCancelEdit = () => {
    setEditingCategory(null);
  };

  // Function to delete category
  const handleDeleteCategory = async (id: string) => {
    // Updated confirmation message
    if (!window.confirm('Are you sure you want to delete this category?')) return;

    setIsLoading(true);
    setError(null);
    try {
      await api.delete(`/categories/${id}`);
      setCategories(categories.filter(cat => cat.id !== id));
    } catch (err: any) {
      console.error('Error deleting category:', err);
      setError(err.response?.data?.error || 'Failed to delete category.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '20px auto', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
      {/* Title */}
      <h2>Manage Categories</h2>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Form to add new category */}
      <form onSubmit={handleAddCategory} style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
        <input
          type="text"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
          // Placeholder text
          placeholder="New category name"
          disabled={isLoading}
          style={{ flexGrow: 1, padding: '8px' }}
        />
        <button type="submit" disabled={isLoading || !newCategoryName.trim()}
          style={{ padding: '8px 15px' }}>
          {/* Button text */}
          {isLoading ? 'Adding...' : 'Add'}
        </button>
      </form>

      {/* Category list */}
      {/* Loading text */}
      {isLoading && categories.length === 0 && <p>Loading categories...</p>}
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {categories.map((category) => (
          <li key={category.id} style={{ borderBottom: '1px solid #eee', padding: '10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {editingCategory && editingCategory.id === category.id ? (
              // Edit mode
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexGrow: 1 }}>
                <input
                  type="text"
                  value={editingCategory.name}
                  onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                  autoFocus
                  style={{ flexGrow: 1, padding: '8px' }}
                  disabled={isLoading}
                />
                {/* Button text */}
                <button onClick={handleSaveEdit} disabled={isLoading || !editingCategory.name.trim()} style={{ padding: '5px 10px', color: 'green' }}>Save</button>
                {/* Button text */}
                <button onClick={handleCancelEdit} disabled={isLoading} style={{ padding: '5px 10px' }}>Cancel</button>
              </div>
            ) : (
              // View mode
              <>
                <span>{category.name}</span>
                <div>
                  {/* Button text */}
                  <button onClick={() => handleEditClick(category)} disabled={isLoading} style={{ marginRight: '5px', padding: '5px 10px' }}>Edit</button>
                  {/* Button text */}
                  <button onClick={() => handleDeleteCategory(category.id)} disabled={isLoading} style={{ padding: '5px 10px', color: 'red' }}>Delete</button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryManager; 