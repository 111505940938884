import { useToast } from '@/hooks/use-toast';
import { auth } from '@/integrations/database/auth';
import axios from 'axios'; // Import axios to check for AxiosError

// Mapeamento de mensagens de erro
const errorMessages: Record<string, string> = {
  'auth/wrong-password': 'Incorrect password',
  'auth/user-not-found': 'User not found',
  'auth/email-already-in-use': 'This email is already in use',
  'auth/weak-password': 'Password must be at least 6 characters long',
  'auth/missing-fields': 'Please fill in all fields',
  'auth/signup-failed': 'Error creating account',
  'auth/signin-failed': 'Error logging in',
  'auth/unknown-error': 'An unknown error occurred'
};

const getErrorMessage = (error: unknown): string => {
  // Check specifically for Axios errors and our backend's message format
  if (axios.isAxiosError(error) && error.response?.data?.message && typeof error.response.data.message === 'string') {
    return error.response.data.message;
  }

  // Fallback to previous logic for other errors (e.g., Firebase or generic errors)
  if (error instanceof Error) {
    if ('code' in error) { // Checks for Firebase-like error codes
      const authError = error as any;
      return errorMessages[authError.code] || authError.message;
    }
    return error.message;
  }
  return errorMessages['auth/unknown-error'] || 'An unknown error occurred'; // Use defined unknown error message
};

export const useAuthOperations = (setIsLoading: (loading: boolean) => void) => {
  const { toast } = useToast();

  const login = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      
      const response = await auth.signIn(email, password);
      
      if (response.session?.token) {
        localStorage.setItem('auth-token', response.session.token);
        
        // Verifica o token imediatamente após o login
        const userData = await auth.verifyToken(response.session.token);
        if (userData) {
          response.user = userData;
        }
      }

      return response;
    } catch (error) {
      console.error('Login error:', error);
      const message = getErrorMessage(error);
      toast({
        title: 'Login Error',
        description: message,
        variant: 'destructive',
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signup = async (email: string, password: string, name: string) => {
    try {
      setIsLoading(true);
      
      const response = await auth.signUp(email, password, name);
      
      if (response.session?.token) {
        localStorage.setItem('auth-token', response.session.token);
        
        // Verifica o token imediatamente após o cadastro
        const userData = await auth.verifyToken(response.session.token);
        if (userData) {
          response.user = userData;
        }
      }

      toast({
        title: "Account created successfully!",
        description: "Welcome to Prismatic Economy!",
      });
      
      return response;
    } catch (error) {
      console.error('Signup error:', error);
      const message = getErrorMessage(error);
      toast({
        title: 'Error creating account',
        description: message,
        variant: 'destructive',
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      setIsLoading(true);
      
      await auth.signOut();
      localStorage.removeItem('auth-token');
      
      toast({
        title: "Logged out",
        description: "You have been successfully logged out.",
      });
      return null;
    } catch (error) {
      console.error('Logout error:', error);
      const message = getErrorMessage(error);
      toast({
        title: 'Logout Error',
        description: message,
        variant: 'destructive',
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    login,
    signup,
    logout
  };
};
