import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MainLayout from "@/layouts/MainLayout";
import { useAuth } from "@/contexts/auth";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import EBookGrid from "@/components/ebooks/EBookGrid";
import { BookOpen, CreditCard, Settings, User, Loader2, AlertCircle } from "lucide-react";
import { useBooks } from "@/hooks/use-books";
import { useRecentBooks } from "@/hooks/use-recent-books";
import { useFavorites } from "@/contexts/FavoritesContext";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { EBook } from "@/components/ebooks/EBookCard";

// --- DEFINIR TIPO MAIS ESPECÍFICO --- 
// (Igual ao que fizemos em Library.tsx)
// Estende EBook, mas garante que categories é sempre um array
interface Category { id: string; name: string; } // Precisa de Category aqui
interface EBookWithCategories extends Omit<EBook, 'categories'> { 
  categories: Category[];
}
// --- FIM DA DEFINIÇÃO ---

const Dashboard = () => {
  const { user, isLoading: isLoadingAuth, isAdmin, isSubscribed } = useAuth();
  const { books: recommendedBooks, isLoading: isLoadingRecommended, error: errorRecommended } = useBooks();
  const { recentBooks, isLoading: isLoadingRecent, error: errorRecent } = useRecentBooks();
  const { favoriteBooks, isLoading: isLoadingFavorites, isFavorited } = useFavorites();
  const navigate = useNavigate();
  
  useEffect(() => {
    console.log("[Dashboard] favoriteBooks from context updated:", favoriteBooks);
  }, [favoriteBooks]);
  
  useEffect(() => {
    // Redirect if not logged in
    if (!isLoadingAuth && !user) {
      navigate("/login");
    }
  }, [user, isLoadingAuth, navigate]);
  
  if (isLoadingAuth || !user) {
    return (
      <MainLayout>
        <div className="container mx-auto py-20 flex justify-center">
          <div className="animate-pulse flex space-x-4">
            <div className="rounded-full bg-muted h-10 w-10"></div>
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-muted rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-muted rounded col-span-2"></div>
                  <div className="h-2 bg-muted rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-muted rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
  
  // Helper function to render book grids or placeholders
  const renderBookContent = (books: EBookWithCategories[], isLoading: boolean, error: Error | null, emptyTitle: string, emptyDesc: string) => {
    if (isLoading) {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {[...Array(4)].map((_, i) => ( // Show fewer placeholders
            <Card key={i} className="animate-pulse">
              <CardContent className="p-4">
                <div className="aspect-[3/4] bg-muted rounded-md mb-4"></div>
                <div className="h-4 bg-muted rounded w-3/4 mb-2"></div>
                <div className="h-3 bg-muted rounded w-1/2"></div>
              </CardContent>
            </Card>
          ))}
        </div>
      );
    }
    
    if (error) {
      return (
        <Alert variant="destructive" className="max-w-lg mx-auto">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error Loading Books</AlertTitle>
          <AlertDescription>
            {error.message || "Could not fetch books for this section."}
          </AlertDescription>
        </Alert>
      );
    }

    if (books.length === 0) {
      return (
        <div className="text-center py-12 border border-dashed rounded-lg">
          {/* Use appropriate icon? Maybe BookOpen? */}
          <BookOpen className="h-12 w-12 mx-auto text-muted-foreground mb-4 opacity-50" />
          <h3 className="text-lg font-medium mb-2">{emptyTitle}</h3>
          <p className="text-muted-foreground mb-4 text-sm">
            {emptyDesc}
          </p>
          <Button variant="outline" size="sm" onClick={() => navigate("/library")}>
            Browse Library
          </Button>
        </div>
      );
    }
    
    // Render the grid if books exist
    return <EBookGrid books={books} />;
  };

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-3xl font-bold">Dashboard</h1>
            <p className="text-muted-foreground">
              Welcome back, {user.name || user.email}
            </p>
          </div>
          {isAdmin() && (
            <Button onClick={() => navigate("/admin")} className="btn-hover">
              Admin Panel
            </Button>
          )}
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <Card className="glass">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                Account Status
              </CardTitle>
              <User className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">
                {isSubscribed() ? "Premium" : "Free"}
              </p>
              <p className="text-xs text-muted-foreground">
                {isSubscribed() 
                  ? "Full access to all eBooks" 
                  : "Limited access to content"}
              </p>
            </CardContent>
            <CardFooter>
              {!isSubscribed() && (
                <Button size="sm" className="w-full" onClick={() => navigate("/pricing")}>
                  Upgrade
                </Button>
              )}
            </CardFooter>
          </Card>
          
          <Card className="glass">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                Reading Activity
              </CardTitle>
              <BookOpen className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">0</p>
              <p className="text-xs text-muted-foreground">
                Books in progress
              </p>
            </CardContent>
            <CardFooter>
              <Button size="sm" variant="outline" className="w-full" onClick={() => navigate("/library")}>
                Browse Library
              </Button>
            </CardFooter>
          </Card>
          
         
        </div>
        
        <Tabs defaultValue="recent" className="w-full">
          <TabsList className="mb-6">
            {/* Recommended tab might be removed or kept simple */}
            {/* <TabsTrigger value="recommended">Recommended</TabsTrigger> */}
            <TabsTrigger value="recent">Recent</TabsTrigger>
            <TabsTrigger value="favorites">Favorites</TabsTrigger>
          </TabsList>
          
          {/* Recommended Content - Simplified or removed? Using existing useBooks for now */}
          {/* 
          <TabsContent value="recommended" className="animate-fade-in">
            {renderBookContent(
              recommendedBooks.filter(book => isSubscribed() || !book.isPremium).slice(0, 8) as EBookWithCategories[], 
              isLoadingRecommended, 
              errorRecommended, 
              "No recommendations available", 
              "Explore the library to find books."
            )}
          </TabsContent>
          */}
          
          <TabsContent value="recent" className="animate-fade-in">
            {renderBookContent(
              recentBooks as EBookWithCategories[], 
              isLoadingRecent, 
              errorRecent, 
              "No recent activity", 
              "Start reading to see your recent books here"
            )}
          </TabsContent>
          
          <TabsContent value="favorites" className="animate-fade-in">
             {renderBookContent(
              favoriteBooks as EBookWithCategories[], 
              isLoadingFavorites, 
              null, 
              "No favorites yet", 
              "Add books to your favorites to see them here"
            )}
          </TabsContent>
        </Tabs>
      </div>
    </MainLayout>
  );
};

export default Dashboard;
