import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { FileText, BookOpen, Heart, Loader2, X } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useIsMobile } from '@/hooks/use-mobile';
import PDFViewerNew from '@/components/pdf/PDFViewerNew';
import { useToast } from '@/hooks/use-toast';
import { api } from '@/integrations/database/auth'; // Import configured axios instance
import { Progress } from '@/components/ui/progress'; // Import Progress component
import { useAuth } from "@/contexts/AuthContext"; // Import useAuth
import { cn } from "@/lib/utils";
import { useFavorites } from "@/contexts/FavoritesContext"; // Import useFavorites
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useSettings } from "@/contexts/SettingsContext"; // Import useSettings

// Definir Category aqui ou importar
interface Category {
  id: string;
  name: string;
}

// Interface EBook CORRIGIDA para refletir os dados recebidos
// (Idealmente, importar de um tipo compartilhado)
export interface EBook {
  id: string;
  title: string;
  author: string;
  description: string | null;
  categories?: Category[]; 
  coverImageUrl?: string | null; // <<< Correto da API
  isPremium: boolean;
  filePath?: string; // <<< Correto da API
  totalPages?: number | null;
  isFavoritedByUser?: boolean;
}

interface EBookCardProps {
  book: EBook;
  onReadClick?: (book: EBook) => void; // Keep this if needed elsewhere
}

const EBookCard: React.FC<EBookCardProps> = ({ book }) => {
  // Adicionar Log no início
  console.log(`[EBookCard ${book.id}] Received book prop:`, {
    ...book,
    coverImageUrl: book.coverImageUrl,
    filePath: book.filePath
  });
  
  const [showViewer, setShowViewer] = useState(false);
  const [isLoadingViewer, setIsLoadingViewer] = useState(false);
  const [lastPageRead, setLastPageRead] = useState<number>(1);
  const [isFetchingProgress, setIsFetchingProgress] = useState(false);
  const isMobile = useIsMobile();
  const { toast } = useToast();
  const [fileUrlToLoad, setFileUrlToLoad] = useState<string | null>(null);
  const { user, isSubscribed } = useAuth();
  const { settings, isLoading: isLoadingSettings } = useSettings();
  const navigate = useNavigate();

  // --- Use Favorites Context --- 
  const { addFavorite, removeFavorite, isFavorited } = useFavorites();
  const [isUpdatingFavorite, setIsUpdatingFavorite] = useState(false);

  // Determine favorite status from context
  const currentlyFavorited = isFavorited(book.id);

  // Fetch progress when component mounts or book.id changes
  useEffect(() => {
    const fetchProgress = async () => {
      const token = localStorage.getItem('auth-token');
      if (!token || !user) { 
        console.log(`[EBookCard ${book.id}] Skipping progress fetch: Not logged in.`);
        setLastPageRead(1); 
        return; 
      }

      setIsFetchingProgress(true);
      try {
        console.log(`[EBookCard ${book.id}] Fetching progress (for any logged in user)...`);
        const response = await api.get(`/books/progress/${book.id}`);
        setLastPageRead(response.data.currentPage || 1);
        console.log(`[EBookCard ${book.id}] Progress fetched: Page ${response.data.currentPage}`);
      } catch (error) {
        console.error(`[EBookCard ${book.id}] Error fetching progress:`, error);
        setLastPageRead(1);
      } finally {
        setIsFetchingProgress(false);
      }
    };

    fetchProgress();
  }, [book.id, user]);

  // Reset loading state when dialog/drawer is closed
  useEffect(() => {
    if (!showViewer) {
      setIsLoadingViewer(false);
      setFileUrlToLoad(null);
    }
  }, [showViewer]);

  const handleReadClick = async () => {
    if (!user) {
      toast({ title: "Login Required", description: "Log in to read.", variant: "default" });
      return;
    }
    
    console.log(`[EBookCard ${book.id}] File Path:`, book.filePath);
    
    if (!book.filePath) {
      toast({ title: "Error", description: "File path not found.", variant: "destructive" });
      return;
    }
    
    if (book.isPremium && !isSubscribed()) {
      toast({
        title: "Premium Content",
        description: "Subscribe to access this book.",
        variant: "default",
        action: (
          <Button size="sm" onClick={() => navigate('/pricing')}>
            View Plans
          </Button>
        ),
        duration: 8000,
      });
      return;
    }

    setIsLoadingViewer(true);
    
    try {
      const token = localStorage.getItem('auth-token');
      if (!token) throw new Error("Auth token missing");

      let fullUrl = book.filePath; 
      if (!fullUrl.startsWith('http') && !fullUrl.startsWith('/')) {
        fullUrl = `/${fullUrl}`;
      }
      const constructedPdfUrl = `${fullUrl}?token=${token}`;
      
      console.log(`[EBookCard ${book.id}] Constructed PDF URL: ${constructedPdfUrl}`);

      setFileUrlToLoad(constructedPdfUrl);
      setShowViewer(true); 

    } catch (error: any) {
      console.error(`[EBookCard ${book.id}] Error opening book:`, error);
      toast({ title: "Error", description: error.message || "Could not load the book.", variant: "destructive" });
      setIsLoadingViewer(false);
    } 
  };

  const handleClose = () => {
    setShowViewer(false);
  };

  const handlePageChange = async (pageNumber: number) => {
    if (!user) {
        console.log('[EBookCard] Skipping progress save: User not logged in.');
        return;
    }

    try {
      console.log(`[EBookCard] Attempting to save progress: User ${user.id}, Book ${book.id}, Page ${pageNumber}`);
      await api.post(`/books/progress/${book.id}`, { 
        currentPage: pageNumber,
        totalPages: book.totalPages || 100 
      }); 
      setLastPageRead(pageNumber);
      console.log(`[EBookCard] Progress save request sent successfully for page ${pageNumber}`);
    } catch (error) {
      console.error("[EBookCard] Error saving progress:", error);
    }
  };

  // Calculate progress percentage - SEMPRE calcular se houver totalPages
  const progressPercentage = book.totalPages && book.totalPages > 0
    ? Math.round((lastPageRead / book.totalPages) * 100)
    : 0;

  // --- LÓGICA DA CAPA ORIGINAL ---
  const constructedCoverUrl = book.coverImageUrl
    ? (book.coverImageUrl.startsWith('/') ? book.coverImageUrl : `/${book.coverImageUrl}`)
    : '/images/default-cover.svg';
  // --- FIM LÓGICA ORIGINAL ---
  
  // Debug log simplificado
  console.log(`[EBookCard ${book.id}] Cover Info:`, {
    receivedCoverUrl: book.coverImageUrl,
    finalConstructedUrl: constructedCoverUrl
  });

  const handleFavoriteToggle = async () => {
    if (!user) { 
      toast({ title: "Login Required", description: "You need to be logged in.", variant: "default" });
      return;
    }
    
    if (!book || !book.id) return;

    setIsUpdatingFavorite(true);
    try {
      if (isFavorited(book.id)) { 
        await removeFavorite(book.id); 
      } else {
        await addFavorite(book); 
      }
    } catch (error) {
      console.error("[EBookCard] Error toggling favorite (API call likely failed, see context errors):", error);
    } finally {
      setIsUpdatingFavorite(false);
    }
  };

  // Debug log before rendering
  console.log(`[EBookCard Render ${book.id}]`, {
    totalPages: book.totalPages,
    lastPageRead: lastPageRead,
    progressPercentage: progressPercentage,
    categories: book.categories
  });

  return (
    <>
      <Card className="flex flex-col h-full overflow-hidden group relative">
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2 z-10 h-8 w-8 bg-background/70 hover:bg-background/90 rounded-full backdrop-blur-sm"
          onClick={handleFavoriteToggle}
          disabled={isLoadingSettings || isUpdatingFavorite} 
          aria-label={isFavorited(book.id) ? "Remove from favorites" : "Add to favorites"}
        >
          {isUpdatingFavorite ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <Heart 
              className={cn(
                "h-4 w-4", 
                isFavorited(book.id) 
                  ? "text-red-600 fill-red-600" 
                  : "text-muted-foreground"
              )}
            />
          )}
        </Button>

        <CardHeader className="p-4 pb-2"> 
          <div className="aspect-[3/4] relative mb-4">
            <img
              src={constructedCoverUrl}
              alt={book.title}
              className="w-full h-full object-cover rounded-md"
              onError={(e) => {
                (e.target as HTMLImageElement).src = '/images/default-cover.svg'; 
              }}
            />
            {book.isPremium && (
              <Badge className="absolute top-2 right-2 bg-primary text-primary-foreground"> 
                Premium
              </Badge>
            )}
          </div>
          <CardTitle className="text-base font-semibold leading-tight group-hover:text-primary transition-colors line-clamp-2">
            {book.title}
          </CardTitle>
          <p className="text-sm text-muted-foreground mt-1 line-clamp-1">{book.author}</p>
          {book.categories && book.categories.length > 0 && (
            <p className="text-xs text-muted-foreground mt-1 line-clamp-1">
              {book.categories.map(cat => cat.name).join(', ')}
            </p>
          )}
        </CardHeader>
        
        <CardContent className="p-4 pt-0 flex-1 flex flex-col justify-end">
          {book.totalPages && (
            <div className="mt-2 mb-3">
              <Progress value={progressPercentage} className="h-1.5" />
              <p className="text-xs text-muted-foreground mt-1 text-center">
                {progressPercentage > 0 ? `${progressPercentage}% complete (Page ${lastPageRead})` : 'Start Reading'}
              </p>
            </div>
          )}

          <Button 
            onClick={handleReadClick} 
            className="w-full mt-auto" 
            disabled={isLoadingViewer}
          >
            {isLoadingViewer ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <BookOpen className="mr-2 h-4 w-4" />
            )}
            {isLoadingViewer ? 'Loading...' : 'Read Now'}
          </Button>
        </CardContent>
      </Card>

      <Dialog open={showViewer} onOpenChange={handleClose}>
        <DialogContent className={cn(
            "p-0 max-w-none w-full h-full sm:h-[95vh] sm:max-w-[95vw] flex flex-col",
          )}>
          
          <Button
            variant="destructive"
            size="icon"
            className="absolute top-2 right-2 z-20 rounded-full h-8 w-8"
            onClick={handleClose}
            aria-label="Close PDF viewer"
          >
            <X className="h-4 w-4 text-destructive-foreground" />
          </Button>

          <DialogHeader className="px-4 pt-4 pb-2 sm:px-6 sm:pt-6 sm:pb-3 border-b sticky top-0 bg-background z-10">
             <DialogTitle className="truncate">{book.title}</DialogTitle>
             <DialogDescription className="truncate">by {book.author}</DialogDescription>
           </DialogHeader>

           <div className="flex-1 overflow-hidden"> 
             {fileUrlToLoad ? (
               <PDFViewerNew 
                 url={fileUrlToLoad}
                 initialPage={lastPageRead}
                 onPageChange={handlePageChange} 
               />
             ) : (
               <div className="flex justify-center items-center h-full">
                 <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
               </div>
             )}
           </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EBookCard;
