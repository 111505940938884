import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/auth";
import MainLayout from "@/layouts/MainLayout";
import AuthForm from "@/components/auth/AuthForm";
import { useEffect, useState } from "react";
import { useToast } from "@/hooks/use-toast";

interface LocationState {
  from?: string;
}

const Login = () => {
  const { login, user, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const [formSubmitting, setFormSubmitting] = useState(false);
  
  const handleLogin = async (email: string, password: string) => {
    try {
      setFormSubmitting(true);
      console.log("Login attempt with:", email);
      await login(email, password);
      // Se o login for bem-sucedido, o useEffect abaixo cuidará do redirecionamento.
      // Não precisamos fazer nada aqui em caso de sucesso.
    } catch (error: any) { // Usar 'any' para acessar propriedades do erro Axios
      // <<< Adicionar Logs Detalhados >>>
      console.error("[Login.tsx catch] Login error object:", error); // Log 1: O erro bruto

      let description = "An unexpected error occurred. Please try again.";
      let errorStatus = null;

      if (error.response) {
        console.log("[Login.tsx catch] Error has response property:", error.response); // Log 2: Detalhes da resposta
        errorStatus = error.response.status;
        if (errorStatus === 401 || errorStatus === 400 || errorStatus === 403) {
           description = "Incorrect email or password. Please try again.";
        } else {
           description = `Login failed with status: ${errorStatus}. Please try again later.`;
        }
      } else if (error.request) {
         console.log("[Login.tsx catch] Error has request property (no response received):", error.request); // Log 3: Erro de rede?
         description = "Could not connect to the server. Please check your network.";
      } else {
         console.log("[Login.tsx catch] Error has no response/request:", error.message); // Log 4: Erro genérico
         description = error.message || description;
      }

      console.log(`[Login.tsx catch] Preparing toast with description: \"${description}\"`); // Log 5: Mensagem final
      
      toast({
        title: "Login Failed", 
        description: description,
        variant: "destructive",
        duration: 9000,
      });
      console.log("[Login.tsx catch] Toast function called."); // Log 6: Confirma chamada
      // <<< Fim dos Logs Detalhados >>>
    } finally {
      setFormSubmitting(false);
    }
  };
  
  useEffect(() => {
    // Only redirect if we have a user and we're not in an initial loading state
    if (user && !isLoading) {
      console.log("User authenticated, checking role:", user.role);
      const state = location.state as LocationState;
      const from = state?.from || (user.role === 'admin' ? '/admin' : '/dashboard');
      navigate(from, { replace: true });
    }
  }, [user, isLoading, navigate, location.state]);
  
  // Se estiver carregando a autenticação inicial, mostra o loading
  if (isLoading) {
    return (
      <MainLayout>
        <div className="container max-w-6xl mx-auto px-4 py-16 flex flex-col items-center justify-center">
          <div className="animate-pulse flex space-x-4">
            <div className="rounded-full bg-muted h-10 w-10"></div>
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-muted rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-muted rounded col-span-2"></div>
                  <div className="h-2 bg-muted rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-muted rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
  
  // Se não estiver carregando e não tiver usuário, mostra o formulário
  return (
    <MainLayout>
      <div className="container max-w-6xl mx-auto px-4 py-16 flex flex-col items-center justify-center">
        <AuthForm 
          type="login" 
          onSubmit={handleLogin} 
          isLoading={formSubmitting} 
        />
      </div>
    </MainLayout>
  );
};

export default Login;
