import MainLayout from "@/layouts/MainLayout";

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold">Privacy Policy</h1>
        <div className="max-w-3xl mx-auto text-lg text-muted-foreground space-y-6">
          <p>
            <strong>Last updated: {new Date().toLocaleDateString()}</strong>
          </p>
          <p>
            Welcome to Our Healthy Space. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-3">Information We Collect</h2>
          <p>
            We may collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining information about us or our products and services, when you participate in activities on the website, or otherwise when you contact us.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-3">How We Use Your Information</h2>
          <p>
            We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
          </p>
          {/* Add more sections as needed: Sharing Your Information, Security, Your Rights, Contact Us, etc. */}
          <p className="mt-8">
            Please review this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyPolicy; 