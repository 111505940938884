/**
 * This utility function is for testing the create-admin edge function.
 * It can be used to create a new admin user via the edge function.
 */
export const createAdminViaEdgeFunction = async (
  email: string, 
  password: string, 
  name: string
): Promise<{ success: boolean; message: string; user?: any }> => {
  try {
    // Get the token from localStorage
    const token = localStorage.getItem('auth-token');
    if (!token) {
      // Handle case where user isn't logged in on the client-side
      console.error("Auth token not found in localStorage.");
      return {
        success: false,
        message: "Authentication token not found. Please log in."
      };
    }

    const response = await fetch("/api/auth/create-admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add the Authorization header
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        email,
        password,
        name,
      }),
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.error || "Failed to create admin user");
    }
    
    return {
      success: true,
      message: "Admin user created successfully!",
      user: data.user
    };
  } catch (error) {
    console.error("Error creating admin:", error);
    return {
      success: false,
      message: error instanceof Error ? error.message : "Unknown error occurred"
    };
  }
};
