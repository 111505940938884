import { Link, NavLink } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Menu, BookOpen, Home, Library, Tag, LayoutGrid, LogIn, LogOut, UserPlus, Database, User } from 'lucide-react'; // Added icons
import { useAuth } from '@/contexts/AuthContext'; // Import useAuth
import { cn } from '@/lib/utils';
// import UserAccountNav from './UserAccountNav'; // Assuming this handles user avatar/dropdown - Removed as it caused error

export const Header = () => {
  const { user, logout } = useAuth(); // Get user and logout function

  const navItems = [
    { label: 'Home', href: '/', icon: Home },
    { label: 'Library', href: '/library', icon: Library },
    // { label: 'Pricing', href: '/pricing', icon: Tag }, // <<< COMENTADO
  ];

  const loggedInNavItems = [
    ...navItems,
    { label: 'Dashboard', href: '/dashboard', icon: LayoutGrid }, // Add Dashboard for logged in users
    { label: 'Profile', href: '/profile', icon: User }, // <<< Adicionado Profile
  ];

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 items-center">
        {/* Logo/Brand */}
        <Link to="/" className="mr-6 flex items-center space-x-2">
          <BookOpen className="h-6 w-6 text-primary" />
          <span className="font-bold inline-block">OurHealthySpace</span>
        </Link>

        {/* Desktop Navigation (Hidden on Mobile) */}
        <nav className="hidden md:flex md:items-center md:gap-6 text-sm font-medium flex-1">
          {(user ? loggedInNavItems : navItems).map((item) => (
            <NavLink
              key={item.href}
              to={item.href}
              className={({ isActive }) =>
                cn(
                  "transition-colors hover:text-foreground/80",
                  isActive ? "text-foreground" : "text-foreground/60"
                )
              }
            >
              {item.label}
            </NavLink>
          ))}
        </nav>

        {/* Desktop Auth Buttons / User Menu (Hidden on Mobile) */}
        <div className="hidden md:flex items-center justify-end space-x-4 ml-auto">
          {user ? (
            <>
              {/* Admin Button - Only for admin users */}
              {user.role?.toLowerCase() === 'admin' && (
                <Button asChild variant="outline" size="sm" className="bg-blue-50 text-blue-700 hover:bg-blue-100 dark:bg-blue-900 dark:text-blue-300 dark:hover:bg-blue-800">
                  <Link to="/admin">
                    <Database className="mr-2 h-4 w-4" />
                    Admin Panel
                  </Link>
                </Button>
              )}
              {/* Simple Logout Button */}
               <Button variant="ghost" onClick={logout}>
                 <LogOut className="mr-2 h-4 w-4" /> Logout
               </Button>
            </>
          ) : (
            <>
              <Button variant="ghost" asChild>
                <Link to="/login">
                  <LogIn className="mr-2 h-4 w-4" /> Login
                </Link>
              </Button>
              <Button asChild>
                <Link to="/signup">
                  <UserPlus className="mr-2 h-4 w-4" /> Sign Up
                </Link>
              </Button>
            </>
          )}
        </div>

        {/* Mobile Navigation Trigger */}
        <div className="md:hidden flex flex-1 items-center justify-end">
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon">
                <Menu className="h-5 w-5" />
                <span className="sr-only">Toggle Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <nav className="grid gap-6 text-lg font-medium mt-6">
                {(user ? loggedInNavItems : navItems).map((item) => (
                  <NavLink
                    key={item.href}
                    to={item.href}
                    className={({ isActive }) =>
                      cn(
                        "flex items-center gap-4 px-2.5 transition-colors hover:text-foreground/80",
                        isActive ? "text-foreground" : "text-foreground/60"
                      )
                    }
                  >
                    <item.icon className="h-5 w-5" />
                    {item.label}
                  </NavLink>
                ))}
                
                {user ? (
                  <>
                    {/* Admin Panel no menu mobile */}
                    {user.role?.toLowerCase() === 'admin' && (
                      <Button asChild variant="ghost" className="flex items-center gap-4 px-2.5 text-foreground/60 hover:text-foreground/80 justify-start">
                        <Link to="/admin">
                          <Database className="h-5 w-5" />
                          Admin Panel
                        </Link>
                      </Button>
                    )}
                    <Button variant="ghost" onClick={logout} className="flex items-center gap-4 px-2.5 text-foreground/60 hover:text-foreground/80 justify-start">
                      <LogOut className="h-5 w-5" /> Logout
                    </Button>
                  </>
                ) : (
                  <>
                    <Button variant="ghost" asChild className="flex items-center gap-4 px-2.5 text-foreground/60 hover:text-foreground/80 justify-start">
                      <Link to="/login">
                        <LogIn className="h-5 w-5" /> Login
                      </Link>
                    </Button>
                    <Button variant="ghost" asChild className="flex items-center gap-4 px-2.5 text-foreground/60 hover:text-foreground/80 justify-start">
                      <Link to="/signup">
                        <UserPlus className="h-5 w-5" /> Sign Up
                      </Link>
                    </Button>
                  </>
                )}
              </nav>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </header>
  );
}; 