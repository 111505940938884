import { createBrowserRouter } from 'react-router-dom';
import App from '@/App';
import Home from '@/pages/Index';
import LandingPage from '@/pages/LandingPage';
import Login from '@/pages/Login';
import Signup from '@/pages/Signup';
import Library from '@/pages/Library';
import Dashboard from '@/pages/Dashboard';
import Admin from '@/pages/Admin';
import AdminLayout from '@/layouts/AdminLayout';
import Pricing from '@/pages/Pricing';
import About from '@/pages/About';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import TermsOfService from '@/pages/TermsOfService';
import CookiePolicy from '@/pages/CookiePolicy';
import CompleteSignup from '@/pages/CompleteSignup';
import NotFound from '@/pages/NotFound';
import AuthGuard from '@/components/guards/AuthGuard';
import AdminCategoriesPage from '@/pages/AdminCategoriesPage';
import AdminSettingsPage from '@/pages/AdminSettingsPage';
import Profile from '@/pages/Profile';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'signup',
        element: <Signup />,
      },
      {
        path: 'library',
        element: <Library />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'terms-of-service',
        element: <TermsOfService />,
      },
      {
        path: 'cookie-policy',
        element: <CookiePolicy />,
      },
      {
        path: 'complete-signup',
        element: <CompleteSignup />,
      },
      {
        path: 'dashboard',
        element: (
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        ),
      },
      {
        path: 'profile',
        element: (
          <AuthGuard>
            <Profile />
          </AuthGuard>
        ),
      },
      {
        path: 'admin',
        element: (
          <AuthGuard requireAdmin={true}>
            <AdminLayout />
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: <Admin />
          },
          {
            path: 'categories',
            element: <AdminCategoriesPage />
          },
          {
            path: 'settings',
            element: <AdminSettingsPage />
          },
        ]
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]); 