import MainLayout from "@/layouts/MainLayout";

const About = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-8 text-center">About Us</h1>
        <div className="max-w-3xl mx-auto text-lg text-muted-foreground space-y-6">
          <p>
            Welcome to Our Healthy Space, your central place for accessing and managing 
            a diverse collection of health and wellness eBooks.
          </p>
          <p>
            At Our Healthy Space, we believe in the power of knowledge for a healthier life. 
            We strive to offer a platform that is not only rich in content but also intuitive 
            and enjoyable to use.
          </p>
          <p>
            Our premium subscription gives you unlimited access to our entire library, while 
            our free tier offers a selection of introductory materials. We focus on providing 
            high-quality resources curated by experts, ensuring you receive valuable insights and 
            recommendations. Whether you're seeking fitness tips, nutritional guidance, or mental 
            wellness resources, Our Healthy Space is designed to support your journey towards 
            better well-being.
          </p>
          <p>
            Join our community today and start exploring!
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default About; 