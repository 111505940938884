
import { Link } from "react-router-dom";

type AuthFooterProps = {
  type: "login" | "signup";
};

const AuthFooter = ({ type }: AuthFooterProps) => {
  return (
    <div className="text-sm text-muted-foreground">
      {type === "login" ? (
        <>
          Don't have an account?{" "}
          <Link to="/signup" className="text-primary hover:underline">
          Sign up
          </Link>
        </>
      ) : (
        <>
          Already have an account?{" "}
          <Link to="/login" className="text-primary hover:underline">
            Enter
          </Link>
        </>
      )}
    </div>
  );
};

export default AuthFooter;
