import { useState, useEffect, useCallback } from "react";
import { useToast } from "@/hooks/use-toast";
import { api } from "@/integrations/database/auth";

// Interface para Categoria (pode vir de um arquivo de tipos)
interface Category {
  id: string;
  name: string;
}

interface BookData {
  title: string;
  author: string;
  description: string;
  isPremium: boolean;
  selectedCategoryIds: string[];
}

export interface UseEBookUploadOptions {
  onSuccess?: () => void;
}

export function useEBookUpload({ onSuccess }: UseEBookUploadOptions = {}) {
  const { toast } = useToast();
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [coverFile, setCoverFile] = useState<File | null>(null);
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const [bookData, setBookData] = useState<BookData>({
    title: "",
    author: "",
    description: "",
    isPremium: false,
    selectedCategoryIds: []
  });

  const fetchCategories = useCallback(async () => {
    setIsLoadingCategories(true);
    try {
      const response = await api.get<Category[]>('/categories');
      setAllCategories(response.data);
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
      toast({
        title: "Erro ao carregar categorias",
        description: "Não foi possível buscar a lista de categorias.",
        variant: "destructive"
      });
    } finally {
      setIsLoadingCategories(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const validateFile = (selectedFile: File, type: 'book' | 'image'): boolean => {
    if (type === 'book') {
      const fileName = selectedFile.name.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

      if (fileExtension !== ".pdf") {
        toast({
          title: "Invalid file type",
          description: "Please upload a PDF file",
          variant: "destructive"
        });
        return false;
      }
      
      // Limit file size to 300MB
      if (selectedFile.size > 300 * 1024 * 1024) {
        toast({
          title: "File too large",
          description: "Maximum file size is 300MB",
          variant: "destructive"
        });
        return false;
      }
    } else {
      if (!selectedFile.type.startsWith('image/')) {
        toast({
          title: "Invalid file type",
          description: "Please upload an image file",
          variant: "destructive"
        });
        return false;
      }
      
      // Limit file size to 5MB for images
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast({
          title: "File too large",
          description: "Maximum file size for cover image is 5MB",
          variant: "destructive"
        });
        return false;
      }
    }
    
    return true;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      
      if (validateFile(selectedFile, 'book')) {
        setFile(selectedFile);
      }
    }
  };

  const handleCoverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      
      if (validateFile(selectedFile, 'image')) {
        setCoverFile(selectedFile);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBookData(prev => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (selectedIds: string[]) => {
    setBookData(prev => ({ ...prev, selectedCategoryIds: selectedIds }));
  };

  const resetForm = () => {
    setFile(null);
    setCoverFile(null);
    setBookData({
      title: "",
      author: "",
      description: "",
      isPremium: false,
      selectedCategoryIds: []
    });
  };

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (!file) {
        toast({
          title: "Missing file",
          description: "Please select a PDF file to upload",
          variant: "destructive"
        });
        return;
      }

      if (!bookData.title || !bookData.author) {
        toast({
          title: "Missing information",
          description: "Title and author are required",
          variant: "destructive"
        });
        return;
      }

      setIsUploading(true);

      // Create form data
      const formData = new FormData();
      formData.append('file', file!);
      if (coverFile) {
        formData.append('cover', coverFile);
      }
      formData.append('title', bookData.title);
      formData.append('author', bookData.author);
      formData.append('description', bookData.description || '');
      bookData.selectedCategoryIds.forEach(id => {
        formData.append('categoryIds[]', id);
      });
      formData.append('is_premium', String(bookData.isPremium));

      console.log('Uploading book data:', Object.fromEntries(formData));

      // Upload to server using the configured axios instance
      const response = await api.post('/books', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log("Book uploaded successfully:", response.data);

      // Show success message
      toast({
        title: "Success",
        description: "Book uploaded successfully!"
      });

      // Reset form
      resetForm();
      
      // Notify parent component
      if (onSuccess) {
        onSuccess();
      }
      
    } catch (error: any) {
      console.error("Error uploading book:", error);
      
      // Log detailed error information
      if (error.response) {
        console.error('Error response:', {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers
        });
      }
      
      // Show error message with more details
      const errorMessage = error.response?.data?.details || 
                          error.response?.data?.error || 
                          error.message || 
                          "Failed to upload book";
      
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive"
      });
    } finally {
      setIsUploading(false);
    }
  };

  return {
    file,
    coverFile,
    bookData,
    isUploading,
    allCategories,
    isLoadingCategories,
    setBookData,
    handleFileChange,
    handleCoverChange,
    handleInputChange,
    handleCategoryChange,
    handleUpload
  };
}
