import { TableRow, TableCell } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { ManagedUser } from "../UserManagement";

interface UserRowProps {
  user: ManagedUser;
  onToggleSubscription: (userId: string, newStatus: boolean) => Promise<void>;
}

const UserRow = ({ user, onToggleSubscription }: UserRowProps) => {
  return (
    <TableRow>
      <TableCell className="font-medium">
        {user.name || "No name"}
      </TableCell>
      <TableCell>
        <span
          className={`px-2 py-1 rounded-full text-xs ${
            user.role === "admin"
              ? "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300"
              : "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300"
          }`}
        >
          {user.role || "user"}
        </span>
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          <Checkbox
            checked={user.subscribed || false}
            onCheckedChange={(checked) =>
              onToggleSubscription(user.id, Boolean(checked))
            }
            className="mr-2"
          />
          <span>
            {user.subscribed ? (
              <span className="text-green-600 dark:text-green-400">Active</span>
            ) : (
              <span className="text-gray-500">Inactive</span>
            )}
          </span>
        </div>
      </TableCell>
      <TableCell>
        {user.createdAt
          ? new Date(user.createdAt).toLocaleDateString()
          : "Unknown"}
      </TableCell>
      <TableCell>
        <Button variant="ghost" size="sm" disabled>
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
