import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

// Interface para Categoria (pode vir de um arquivo de tipos)
interface Category {
  id: string;
  name: string;
}

interface BookDetailsFieldsProps {
  bookData: {
    title: string;
    author: string;
    description: string;
    isPremium: boolean;
    selectedCategoryIds: string[];
  };
  allCategories: Category[];
  isLoadingCategories: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPremiumChange: (checked: boolean) => void;
  onCategoryChange: (selectedIds: string[]) => void;
}

const BookDetailsFields = ({ 
  bookData, 
  allCategories,
  isLoadingCategories,
  onInputChange, 
  onPremiumChange, 
  onCategoryChange
}: BookDetailsFieldsProps) => {

  // Handler para o <select multiple>
  const handleSelectMultipleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
    onCategoryChange(selectedOptions);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            name="title"
            value={bookData.title}
            onChange={onInputChange}
            placeholder="Book Title"
            required
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="author">Author</Label>
          <Input
            id="author"
            name="author"
            value={bookData.author}
            onChange={onInputChange}
            placeholder="Author Name"
            required
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          name="description"
          value={bookData.description || ''}
          onChange={onInputChange}
          placeholder="Book description (optional)"
          rows={3}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="categories">Categories</Label>
        {isLoadingCategories ? (
          <p>Loading categories...</p>
        ) : allCategories.length > 0 ? (
          <select
            id="categories"
            multiple
            value={bookData.selectedCategoryIds}
            onChange={handleSelectMultipleChange}
            className="flex h-auto min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            size={5}
          >
            {allCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        ) : (
          <p>No categories found. Please create categories in the admin panel.</p>
        )}
        <p className="text-sm text-muted-foreground">
          Hold Ctrl (or Cmd on Mac) to select multiple categories.
        </p>
      </div>

      <div className="flex items-center space-x-2">
        <Checkbox 
          id="isPremium" 
          checked={bookData.isPremium} 
          onCheckedChange={(checked) => 
            onPremiumChange(Boolean(checked))
          } 
        />
        <Label htmlFor="isPremium" className="cursor-pointer">Premium Content</Label>
      </div>
    </>
  );
};

export default BookDetailsFields;
