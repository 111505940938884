import { ReactNode } from "react";
import { Toaster } from "@/components/ui/toaster";

interface MainLayoutProps {
  children: ReactNode;
  withPadding?: boolean;
}

const MainLayout = ({ children, withPadding = true }: MainLayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <main className={`flex-grow ${withPadding ? 'container mx-auto px-4 pt-20 pb-12' : ''}`}>
        {children}
      </main>
      <Toaster />
    </div>
  );
};

export default MainLayout;
