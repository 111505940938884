
import { useState } from "react";
import MainLayout from "@/layouts/MainLayout";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import UserManagement from "@/components/admin/UserManagement";
import EBookUploadForm from "@/components/admin/EBookUploadForm";
import EBooksManagement from "@/components/admin/EBooksManagement";
import CreateAdminForm from "@/components/admin/CreateAdminForm";

const Admin = () => {
  const [refreshBooks, setRefreshBooks] = useState(0);
  const [refreshUsers, setRefreshUsers] = useState(0);

  const handleBookUploadSuccess = () => {
    // Trigger a refresh of the books list
    setRefreshBooks(prev => prev + 1);
  };

  const handleAdminCreationSuccess = () => {
    // Trigger a refresh of the users list
    setRefreshUsers(prev => prev + 1);
  };

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold mb-6">Administration Panel</h1>
        
        <Tabs defaultValue="users" className="w-full">
          <TabsList className="grid w-full grid-cols-2 mb-8">
            <TabsTrigger value="users">Users</TabsTrigger>
            <TabsTrigger value="books">Ebooks</TabsTrigger>
          </TabsList>
          
          <TabsContent value="users" className="space-y-6">
            <div>
              <h2 className="text-xl font-semibold mb-4">Create New Administrator</h2>
              <CreateAdminForm onSuccess={handleAdminCreationSuccess} />
            </div>
            
            <Card>
              <CardHeader>
                <CardTitle>User Management</CardTitle>
                <CardDescription>Manage users, admins and subscriptions</CardDescription>
              </CardHeader>
              <CardContent>
                <UserManagement key={`users-${refreshUsers}`} />
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="books" className="space-y-6">
            <div>
              <h2 className="text-xl font-semibold mb-4">Upload de eBook</h2>
              <EBookUploadForm onSuccess={handleBookUploadSuccess} />
            </div>
            
            <Card>
              <CardHeader>
                <CardTitle>Book Library</CardTitle>
                <CardDescription>Manage books in the library</CardDescription>
              </CardHeader>
              <CardContent>
                <EBooksManagement refreshTrigger={refreshBooks} />
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </MainLayout>
  );
};

export default Admin;
