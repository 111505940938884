import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { api } from '@/integrations/database/auth';
import { EBook } from '@/components/ebooks/EBookCard';
import { useAuth } from './AuthContext'; // To know if user is logged in
import { useToast } from '@/hooks/use-toast';

// Adicionar tipos locais para API response e Category
interface Category {
  id: string;
  name: string;
}
interface ApiBookResponse {
  id: string;
  title: string;
  author: string;
  description: string | null;
  coverImageUrl: string | null; // From backend
  filePath: string; // From backend
  totalPages?: number | null;
  isPremium: boolean;
  categories?: Category[]; 
  isFavoritedByUser?: boolean; 
}
// --- Fim da adição de tipos ---

interface FavoritesContextType {
  favoriteBooks: EBook[];
  isLoading: boolean;
  addFavorite: (book: EBook) => Promise<void>;
  removeFavorite: (bookId: string) => Promise<void>;
  isFavorited: (bookId: string) => boolean;
}

const FavoritesContext = createContext<FavoritesContextType | undefined>(undefined);

export const FavoritesProvider = ({ children }: { children: ReactNode }) => {
  const [favoriteBooks, setFavoriteBooks] = useState<EBook[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    console.log("[FavoritesContext] favoriteBooks state updated:", favoriteBooks);
  }, [favoriteBooks]);

  useEffect(() => {
    const performFetchFavorites = async () => {
      if (!user) {
        console.log("[FavoritesContext/useEffect] No user, clearing favorites.");
        setFavoriteBooks([]);
        setIsLoading(false);
        return;
      }

      console.log(`[FavoritesContext/useEffect] User ${user.id} present. Fetching favorites...`);
      setIsLoading(true); 
      try {
        console.log("[FavoritesContext/useEffect] >>> BEFORE api.get('/favorites')");
        const response = await api.get<ApiBookResponse[]>('/favorites');
        console.log("[FavoritesContext/useEffect] >>> AFTER api.get('/favorites') - SUCCESS", { status: response.status, data: response.data });
        
        const responseData = Array.isArray(response.data) ? response.data : [];
        console.log("[FavoritesContext/useEffect] Data is array?", Array.isArray(response.data), "Data length:", responseData.length);
        
        const mappedBooks: EBook[] = responseData.map((apiBook, index) => { 
          console.log(`[FavoritesContext/useEffect] Mapping book index ${index} (Before):`, apiBook);
          
          const mappedBook = {
            id: apiBook.id,
            title: apiBook.title,
            author: apiBook.author,
            description: apiBook.description,
            coverImageUrl: apiBook.coverImageUrl,
            filePath: apiBook.filePath,
            totalPages: apiBook.totalPages,
            isPremium: apiBook.isPremium,
            categories: apiBook.categories || [],
            isFavoritedByUser: apiBook.isFavoritedByUser ?? true,
          };
          
          console.log(`[FavoritesContext/useEffect] Mapping book index ${index} (After):`, mappedBook);
          return mappedBook; 
        });
        
        console.log("[FavoritesContext/useEffect] Preparing to set state with mappedBooks:", mappedBooks);
        setFavoriteBooks(mappedBooks); 
        console.log("[FavoritesContext/useEffect] State set. Favorites loaded:", mappedBooks.length);

      } catch (error) {
        console.error("[FavoritesContext/useEffect] CAUGHT ERROR while fetching/mapping favorites:", error);
        setFavoriteBooks([]); 
      } finally {
        setIsLoading(false);
        console.log("[FavoritesContext/useEffect] Fetch completed (finally block).");
      }
    };

    performFetchFavorites();

  }, [user]); 

  const addFavorite = useCallback(async (book: EBook) => {
    if (!user) return;
    const previousFavorites = [...favoriteBooks];
    setFavoriteBooks((prev) => [{ ...book, isFavoritedByUser: true }, ...prev.filter(fb => fb.id !== book.id)]);
    try {
      await api.post('/favorites', { bookId: book.id });
      toast({ title: "Added to Favorites", description: `"${book.title}" added.` });
    } catch (error: any) {
      console.error("[FavoritesContext] Error adding favorite:", error);
      toast({ title: "Error", description: error.response?.data?.message || "Failed to add favorite.", variant: "destructive" });
      setFavoriteBooks(previousFavorites);
    }
  }, [user, toast, favoriteBooks]);

  const removeFavorite = useCallback(async (bookId: string) => {
    if (!user) return;
    const bookToRemove = favoriteBooks.find(book => book.id === bookId);
    const previousFavorites = [...favoriteBooks];
    setFavoriteBooks((prev) => prev.filter((favBook) => favBook.id !== bookId));
    try {
      await api.delete(`/favorites/${bookId}`);
      if (bookToRemove) {
        toast({ title: "Removed from Favorites", description: `"${bookToRemove.title}" removed.` });
      }
    } catch (error: any) {
      console.error("[FavoritesContext] Error removing favorite:", error);
      toast({ title: "Error", description: error.response?.data?.message || "Failed to remove favorite.", variant: "destructive" });
      setFavoriteBooks(previousFavorites);
    }
  }, [user, favoriteBooks, toast]);

  const isFavorited = useCallback((bookId: string) => {
    return favoriteBooks.some(book => book.id === bookId);
  }, [favoriteBooks]);

  return (
    <FavoritesContext.Provider value={{ favoriteBooks, isLoading, addFavorite, removeFavorite, isFavorited }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (context === undefined) {
    throw new Error('useFavorites must be used within a FavoritesProvider');
  }
  return context;
}; 