import { Link } from "react-router-dom";
import { useState } from 'react';
import { useAuth } from "@/contexts/AuthContext";
import { api } from '@/integrations/database/auth';
import { useToast } from '@/hooks/use-toast';
import { Button } from "@/components/ui/button";
import { Loader2, Settings, Github, Twitter, Instagram } from "lucide-react";
import { cn } from "@/lib/utils";

interface FooterProps {
  hideManagePayment?: boolean;
}

const Footer = ({ hideManagePayment = false }: FooterProps) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [isPortalLoading, setIsPortalLoading] = useState(false);

  const handleManageSubscription = async () => {
    if (!user?.subscribed) return; // Should ideally not be clickable if not subscribed

    setIsPortalLoading(true);
    try {
      console.log("[Footer] Calling API to create portal session...");
      // Send the current page URL as the return URL
      const response = await api.post('/stripe/create-portal-session', {
        returnUrl: window.location.href 
      });
      const { portalUrl } = response.data;

      if (!portalUrl) {
        throw new Error('Failed to get Stripe portal URL from server.');
      }
      console.log("[Footer] Received portalUrl:", portalUrl);
      console.log("[Footer] Redirecting to Stripe Portal...");
      window.location.href = portalUrl;

    } catch (error: any) {
      console.error('[Footer] Error handling subscription management:', error);
      toast({
        title: "Error Accessing Portal",
        description: error.response?.data?.error || error.message || "Could not load the customer portal.",
        variant: "destructive",
      });
    } finally {
      setIsPortalLoading(false);
    }
  };

  return (
    <footer className="bg-muted/40 text-muted-foreground border-t border-border/40 mt-auto">
      <div className="container mx-auto px-6 py-8 flex flex-col md:flex-row items-center justify-between text-sm">
        {/* Copyright and Links */}
        <div className="flex flex-col md:flex-row items-center gap-x-6 gap-y-4 mb-4 md:mb-0">
          <div className="text-center md:text-left">
            © {new Date().getFullYear()} OurHealthySpace. All rights reserved.
          </div>
          <div className="flex flex-wrap justify-center md:justify-start gap-x-6 gap-y-2">
            <Link to="/about" className="hover:text-primary transition-colors">About</Link>
            <Link to="/privacy-policy" className="hover:text-primary transition-colors">Privacy Policy</Link>
            <Link to="/terms-of-service" className="hover:text-primary transition-colors">Terms of Service</Link>
            <Link to="/cookie-policy" className="hover:text-primary transition-colors">Cookie Policy</Link>
          </div>
        </div>

        {/* Right Side: Social Icons and Manage Button */}
        <div className="flex items-center gap-x-4">
          {/* Social Icons */}
          <div className="flex gap-x-3">
            <a href="#" aria-label="GitHub" className="text-muted-foreground hover:text-primary transition-colors">
              <Github className="h-5 w-5" />
            </a>
            <a href="#" aria-label="Twitter" className="text-muted-foreground hover:text-primary transition-colors">
              <Twitter className="h-5 w-5" />
            </a>
            <a href="#" aria-label="Instagram" className="text-muted-foreground hover:text-primary transition-colors">
              <Instagram className="h-5 w-5" />
            </a>
          </div>

          {/* Manage Subscription Button - Visible only if logged in, subscribed, and not hidden */}
          {user && user.subscribed && !hideManagePayment && (
            <Button
              variant="link"
              className="p-0 h-auto text-muted-foreground hover:text-primary opacity-75 hover:opacity-100 transition-opacity text-sm"
              onClick={handleManageSubscription}
              disabled={isPortalLoading}
            >
              {isPortalLoading ? (
                <Loader2 className="mr-1.5 h-4 w-4 animate-spin" />
              ) : (
                <Settings className="mr-1.5 h-4 w-4" />
              )}
              Manage Subscription
            </Button>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
