import MainLayout from "@/layouts/MainLayout";
import { useAuth } from "@/contexts/AuthContext";
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"; // Para mostrar iniciais
import { useState, useEffect } from "react";

const Profile = () => {
  const { user } = useAuth();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    if (user !== undefined) {
      setInitialCheckDone(true);
    }
  }, [user]);

  // Função simples para pegar iniciais do nome
  const getInitials = (name?: string) => {
    if (!name) return "?";
    const names = name.split(' ');
    if (names.length === 1) return names[0][0]?.toUpperCase() || "?";
    return (names[0][0]?.toUpperCase() || "") + (names[names.length - 1][0]?.toUpperCase() || "");
  };

  if (!initialCheckDone) {
    return <MainLayout><p>Loading profile...</p></MainLayout>;
  }

  if (!user) {
     // Idealmente redirecionar para login, mas por enquanto mostra mensagem
     return <MainLayout><p>Please log in to view your profile.</p></MainLayout>;
  }

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Your Profile</h1>
        <Card className="max-w-md">
          <CardHeader className="flex flex-row items-center gap-4 pb-2">
             <Avatar className="h-12 w-12">
               {/* Adicionar <AvatarImage src={user.avatarUrl} /> se você tiver avatares */}
               <AvatarFallback>{getInitials(user.name)}</AvatarFallback>
             </Avatar>
             <div>
               <CardTitle className="text-xl">{user.name || "User"}</CardTitle>
               <CardDescription>{user.email}</CardDescription>
             </div>
          </CardHeader>
          <CardContent className="space-y-2 pt-4">
             <p><span className="font-semibold">Subscribed:</span> {user.subscribed ? 'Yes' : 'No'}</p>
             {/* Adicione mais detalhes do usuário se necessário */}
          </CardContent>
        </Card>
      </div>
    </MainLayout>
  );
};

export default Profile;
