import MainLayout from "@/layouts/MainLayout";

const CookiePolicy = () => {
  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold">Cookie Policy</h1>
        <div className="max-w-3xl mx-auto text-lg text-muted-foreground space-y-6">
          <p>
            <strong>Last updated: {new Date().toLocaleDateString()}</strong>
          </p>
          <p>
            Welcome to Our Healthy Space. This Cookie Policy explains what cookies are, how we use them on our website, and your choices regarding cookies.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-3">What Are Cookies?</h2>
          <p>
            Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-3">How We Use Cookies</h2>
          <p>
            When you use and access the Service, we may place a number of cookie files in your web browser. We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences, etc.
          </p>
          {/* Add more sections as needed: Types of Cookies Used, Third-Party Cookies, Your Choices, Changes, Contact Us, etc. */}
          <p className="mt-8">
            Please review this cookie policy carefully.
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default CookiePolicy; 