import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "@/layouts/MainLayout";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useAuth } from "@/contexts/AuthContext";
import { Library, LogIn, Check } from "lucide-react";
import { api } from '@/integrations/database/auth';
import { useToast } from '@/hooks/use-toast';
import { Badge } from "@/components/ui/badge";

// Define your plans - Using the provided Price ID for Monthly
const plans = [
  {
    name: "Monthly",
    priceId: 'price_1R9a8REhPuV4PqvQUBaoghYG', // <-- UPDATED
    price: "$9.99", // You might want to fetch this price dynamically later
    interval: "/ month",
    features: [
      "Access to all premium eBooks",
      "Read progress tracking",
      "Bookmark favorite pages",
      "New content added regularly",
    ],
  },
  {
    name: "Yearly",
    priceId: 'price_1R9aYdEhPuV4PqvQEJWhS9xC', // <-- UPDATED
    price: "$79.99",
    interval: "/ year",
    features: [
      "All Monthly features",
      "Save over 15%",
      "Priority support",
      "Early access to new releases",
    ],
  },
];

const Pricing = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState<string | null>(null); // Track loading state per plan

  const handleNavigate = () => {
    if (user) {
      navigate("/library");
    } else {
      navigate("/login");
    }
  };

  const handleSubscribe = async (priceId: string) => {
    if (!user) {
      toast({ 
        title: "Login Required", 
        description: "Please log in or sign up to subscribe.",
        variant: "default" 
      });
      // Consider redirecting to login: navigate('/login');
      return;
    }

    setIsLoading(priceId);

    try {
      // 1. Call your backend to create a checkout session
      console.log(`[Pricing] Calling API to create checkout for price: ${priceId}`);
      const response = await api.post('/stripe/create-checkout-session', { priceId });
      const { checkoutUrl } = response.data;

      if (!checkoutUrl) {
        throw new Error('Failed to get checkout URL from server.');
      }
      console.log(`[Pricing] Received checkoutUrl: ${checkoutUrl}`);

      // 2. Redirect directly to the Stripe Checkout URL
      console.log('[Pricing] Redirecting to Stripe Checkout URL...');
      window.location.href = checkoutUrl;

    } catch (error: any) {
      console.error('[Pricing] Error handling subscription:', error);
      toast({
        title: "Subscription Error",
        description: error.response?.data?.error || error.message || "An unexpected error occurred.",
        variant: "destructive",
      });
      setIsLoading(null); // Reset loading state on error
    }
  };

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-6 md:py-12">
        <div className="text-center max-w-3xl mx-auto mb-6">
          <span className="inline-block text-sm font-medium text-primary bg-primary/10 px-3 py-1 rounded-full mb-4">
            Subscription Required
          </span>
          <h1 className="text-4xl font-bold mb-6">For full access to all books, choose a subscription below:</h1>
          <p className="text-lg text-muted-foreground">
            Unlock premium content with a simple, transparent pricing structure.
          </p>
        </div>
        
        {/* Optional: Remove or keep the terms/privacy links if relevant */}
        {/* <div className="text-center max-w-md mx-auto mt-6 text-sm text-muted-foreground">
          <p>
            By using our service, you agree to our <a href="/terms" className="text-primary hover:underline">Terms of Service</a> and 
            <a href="/privacy" className="text-primary hover:underline"> Privacy Policy</a>.
          </p>
        </div> */}

        <div className="text-center max-w-2xl mx-auto mt-6">
          <h2 className="text-2xl font-bold mb-4">Choose a Plan</h2>
      
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto mt-4">
          {plans.map((plan) => (
            <Card key={plan.name} className="flex flex-col">
              <CardHeader className="text-center">
                {plan.name === 'Yearly' && (
                  <Badge 
                    className="mb-2 inline-block px-3 py-1 text-sm font-semibold rounded-full bg-blue-600 text-white hover:bg-blue-600/90"
                  >
                    Best Value
                  </Badge>
                )}
                <CardTitle className="text-2xl font-semibold">{plan.name}</CardTitle>
                <CardDescription className="text-4xl font-bold">
                  {plan.price}
                  <span className="text-lg font-normal text-muted-foreground">{plan.interval}</span>
                </CardDescription>
              </CardHeader>
              <CardContent className="flex-1 space-y-3">
                <ul className="space-y-2">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-muted-foreground">{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className="flex flex-col items-center">
                {/* Single Payment Methods Image */}
                <div className="mb-4 w-full text-center">
                  <p className="text-sm text-muted-foreground mb-2">Pay securely with:</p>
                  <div className="flex justify-center">
                     {/* Use the single PNG image */}
                     <img 
                       src="/images/payment/6220ac4b912013c51947f9c5.png" 
                       alt="Payment methods accepted: Visa, Mastercard, Amex, Google Pay, Apple Pay" 
                       className="h-12" // Increased height from h-10 to h-12
                     />
                  </div>
                </div>
                {/* Subscribe Button */}
                <Button 
                  className="w-full btn-hover" 
                  onClick={() => handleSubscribe(plan.priceId)}
                  disabled={isLoading === plan.priceId}
                >
                  {isLoading === plan.priceId ? "Processing..." : "Subscribe Now"}
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default Pricing;
